// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Business} from '../../core/_models'
import {format} from 'date-fns'
import {ReviewRating} from './ReviewRating'
import {Location} from './Location'
import {StatusCell} from '../../../../offers/table/columns/StatusCell'
import {ApprovalStatusCell} from '../../../../offers/table/columns/ApprovalStatusCell'
import {Phone} from './Phone'

const usersColumns: ReadonlyArray<Column<Business>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell business={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    id: 'phone',
    Cell: ({...props}) => <Phone phone={props.data[props.row.index].phone} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Location' className='min-w-180px' />
    ),
    id: 'location',
    Cell: ({...props}) => (
      <Location
        address={props.data[props.row.index].address}
        city={props.data[props.row.index].city}
        zipCode={props.data[props.row.index].zipCode}
        state={props.data[props.row.index].state}
      />
    ),
  },
]

export {usersColumns}
