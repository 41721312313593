import React, {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isEmpty} from 'lodash'
import {QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import {Loading} from '../../data-table/components/loading/Loading'
import Input, {formatPhoneNumber} from 'react-phone-number-input/input'
import {useListView} from '../../user-enquiry/core/ListViewProvider'
import {updateUser} from '../../apps/user-management/users-list/core/_requests'
import {reversePhoneNumber} from '../../../../utils/helperFn'
const profileDetailsSchema = Yup.object().shape({
  last_name: Yup.string().required(' Last name is required'),
  first_name: Yup.string().required('First name is required'),
  phone: Yup.string()
    // .matches(/^[0-9]+$/, 'Phone number must contain only digits')
    .required('Phone No. is required'),
})

const initialValues = {
  last_name: '',
  first_name: '',
  phone: '',
  email: '',
}
const ProfileUpdate: React.FC = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {currentUser, setCurrentUser} = useAuth()

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const modifiedData = {
        ...values,
        phone: values.phone ? formatPhoneNumber(values.phone) : values.phone,
      }
      setLoading(true)
      if (!isEmpty(currentUser) && isNotEmpty(currentUser?.id)) {
        const updatedData = {id: currentUser?.id, ...modifiedData}
        const data = await updateUser(updatedData)

        if (!isEmpty(data)) {
          setCurrentUser(data)
        }
      }

      setLoading(false)
    },
  })

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      const userData = {
        email: currentUser?.email || '',
        last_name: currentUser?.last_name || '',
        first_name: currentUser?.first_name || '',
        phone: currentUser?.phone ? reversePhoneNumber(currentUser?.phone) : '',
      }

      formik.setValues(userData)
    }
  }, [currentUser])
  const phoneChangeHandler = useCallback((value) => {
    formik.setFieldValue('phone', value)
  }, [])

  if (isEmpty(currentUser)) return <Loading />

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('first_name')}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('last_name')}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <Input
                  country='US'
                  {...formik.getFieldProps('phone')}
                  type='text'
                  name='phone'
                  autoComplete='off'
                  maxLength='14'
                  value={formik.values.phone}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Contact No'
                  onChange={phoneChangeHandler}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileUpdate}
