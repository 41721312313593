import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getInformationCategoryById} from '../core/_requests'
import {InformationEditModalForm} from './InformationEditModalForm'

const InformationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: informationCategory,
    error,
  } = useQuery(
    `${QUERIES.INFORMATION_CATEGORY_LIST}-${itemIdForUpdate}`,
    () => {
      return getInformationCategoryById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return (
      <InformationEditModalForm isUserLoading={isLoading} informationCategory={{id: undefined}} />
    )
  }
  if (!isLoading && !error && informationCategory) {
    return (
      <InformationEditModalForm
        isUserLoading={isLoading}
        informationCategory={informationCategory}
      />
    )
  }

  return null
}

export {InformationEditModalFormWrapper}
