/* eslint-disable jsx-a11y/anchor-is-valid */

export function Projects() {
  return (
    <>
        <h3 className='fw-bolder my-2'>
          Projects
        </h3>
    </>
  )
}

