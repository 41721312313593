/* eslint-disable jsx-a11y/anchor-is-valid */


export function Documents() {
  return (
    <>
     <h3 className='fw-bolder my-2'>
          Documents
        </h3>
    </>
  )
}
