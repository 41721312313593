import React, {useState, ChangeEvent, KeyboardEvent} from 'react'

interface TagInputProps {
  tags: string[]
  onChange: (tags: string[]) => void
}

const TagInput: React.FC<TagInputProps> = ({tags, onChange}) => {
  const [input, setInput] = useState<string>('')
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [editedTagIndex, setEditedTagIndex] = useState<number | null>(null)
  const [localTags, setLocalTags] = useState<string[]>(tags)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)
  }

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && input.trim() !== '') {
      event.preventDefault()

      if (isEditMode && editedTagIndex !== null) {
        // Edit mode: Update the existing tag
        const updatedTags = [...localTags]
        updatedTags[editedTagIndex] = input.trim()
        setLocalTags(updatedTags)
        onChange(updatedTags)
        setEditedTagIndex(null)
        setIsEditMode(false)
      } else {
        // Normal mode: Add a new tag
        const updatedTags = [...localTags, input.trim()]
        setLocalTags(updatedTags)
        onChange(updatedTags)
      }

      setInput('')
    }
  }

  const handleTagRemove = (index: number) => {
    const updatedTags = [...localTags]
    updatedTags.splice(index, 1)
    setLocalTags(updatedTags)
    onChange(updatedTags)
  }

  const handleTagEdit = (index: number) => {
    setIsEditMode(true)
    setEditedTagIndex(index)
    setInput(localTags[index])
  }

  const handleInputBlur = () => {
    if (input.trim() !== '') {
      const updatedTags = [...localTags, input.trim()]
      setLocalTags(updatedTags)
      onChange(updatedTags)
      setInput('')
    }
  }

  return (
    <div>
      <div style={{marginLeft: '0px'}}>
        {localTags.map((tag, index) => (
          <div key={index} className='  tag tagstyl '>
            {tag}
            <span onClick={() => handleTagRemove(index)} className='stylstag'>
              &times;
            </span>
            <span onClick={() => handleTagEdit(index)}></span>
          </div>
        ))}
      </div>
      <input
        type='text'
        className='form-control'
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder={isEditMode ? 'Edit tag and press Enter' : 'Type and press Enter to add tags'}
        onBlur={handleInputBlur}
      />
    </div>
  )
}

export default TagInput
