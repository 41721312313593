import React, {useEffect, useState, useCallback} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useLocation, useNavigate} from 'react-router-dom'
import {QUERIES, stringifyRequestQuery, toAbsoluteUrl} from '../../../_metronic/helpers'
import {convertFileToBase64} from '../../../utils/helperFn'
import SliderDetails from './details/SliderDetails'
import {createSlider, getSlider, updateSlider} from './core/_requests'
import {useQuery} from 'react-query'
import {useQueryRequest} from './core/QueryRequestProvider'
import {ToastContainer, toast} from 'react-toastify'
const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required(' Description is required'),
})

const MainSliderForm: React.FC = () => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState(stringifyRequestQuery(state))

  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [token, setToken] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const tokenData = params.get('token')
  const userID = params.get('id')

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.HDR_QNA_URL}-${query}`,
    () => {
      return getSlider(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const sliderData = response

  const [formValues, setFormValues] = useState({
    buttonName: '',
    urlLink: '',
    description: '',
    title: '',
    image: '',
  })

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  useEffect(() => {
    if (tokenData && userID) {
      setToken((preVal) => (preVal = tokenData))
      setUserId((preVal) => (preVal = userID))
    }
  }, [])

  const [loading, setLoading] = useState(false)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const handleEdit = (data) => {
    setFormValues({
      buttonName: data.buttonName || '',
      urlLink: data.urlLink || '',
      description: data.description || '',
      title: data.title || '',
      image: data.image || '',
    })

    setSelectedItemId(data.id)
  }

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      setLoading(true)
      try {
        if (selectedItemId) {
          await updateSlider(selectedItemId, values)

          resetForm()
          refetch()
        } else {
          await createSlider(values)

          refetch()
        }
        resetForm()
        handleImageRemove('image')
      } catch (ex) {
        console.error(ex)
        toast.error(`Error updating slider: ${ex}`, {position: 'top-right'})
      } finally {
        setSubmitting(false)
        setSelectedItemId(null)
        setLoading(false)
        setFormValues({
          buttonName: '',
          urlLink: '',
          description: '',
          title: '',
          image: '',
        })
        refetch()
      }
    },
  })

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = e.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      const base64 = await convertFileToBase64(file)

      // Load image to check resolution and aspect ratio
      const image = new Image()
      const loadImage = new Promise<void>((resolve, reject) => {
        image.onload = () => resolve()
        image.onerror = () => reject(new Error('Image loading failed'))
      })
      image.src = imageUrl

      try {
        await loadImage

        const aspectRatio = image.width / image.height
        const targetAspectRatio = 16 / 9

        // Check if the image maintains the 16:9 aspect ratio
        if (aspectRatio !== targetAspectRatio) {
          toast.error('Image resolution must maintain a 16:9 aspect ratio.')
          return
        }

        if (image.width > 1920 || image.height > 1080) {
          toast.error('Image resolution should not exceed 1920x1080px.')
          return
        }

        if (type === 'thumbnail' || type === 'image') {
          formik.setFieldValue(type, base64)
          setSelectedImage(imageUrl)
        }
      } catch (error) {
        console.error(error)
        alert('An error occurred while processing the image.')
      }
    }
  }

  const handleImageRemove = (type: string) => {
    setSelectedImage(null)
    if (type === 'thumbnail') {
      formik.setFieldValue('thumbnail', '')
    }
    if (type === 'image') {
      formik.setFieldValue('image', '')
    }
  }

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = sliderData.find((item) => item.id === selectedItemId)
      formik.setValues({
        buttonName: selectedItem.buttonName || '',
        urlLink: selectedItem.urlLink || '',
        description: selectedItem.description || '',
        title: selectedItem.title || '',
        image: selectedItem.image || '',
      })
      setSelectedImage(selectedItem.image || null)
    }
  }, [selectedItemId, sliderData, formik.setValues])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Slider</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <form
              onSubmit={formik.handleSubmit}
              id='kt_signin_change_email'
              className='form'
              noValidate
            >
              <div className='row mb-6'>
                <div className='col-lg-12 '>
                  <div className='fv-row mb-7 text-center'>
                    <label className='d-block fw-bold fs-6 mb-5'>Image</label>

                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url('${blankImg}')`}}
                    >
                      <div
                        className='slider-image-Show'
                        style={{
                          backgroundImage: `url('${
                            (formik as any)?.image ? (formik as any)?.image : selectedImage
                          }')`,
                        }}
                      ></div>

                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title='Change image'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>

                        <input
                          type='file'
                          onChange={(e) => handleImageChange(e, 'image')}
                          name='image'
                          accept='.png, .jpg, .jpeg'
                        />
                        <input type='hidden' name='avatar_remove' />
                      </label>

                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        title='Cancel image'
                        onClick={() => handleImageRemove('image')}
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>

                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove image'
                        onClick={() => handleImageRemove('image')}
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>

                    <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                  </div>
                </div>
                <div className='col-lg-4 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='text' className='form-label fs-6 fw-bolder mb-3'>
                      Enter Title
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='title'
                      name='title'
                      placeholder='Enter Title'
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-4 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='buttonName' className='form-label fs-6 fw-bolder mb-3'>
                      Button Name
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='buttonName'
                      name='buttonName'
                      placeholder='Button Title'
                      value={formik.values.buttonName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.buttonName && formik.errors.buttonName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.buttonName}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-4 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='urlLink' className='form-label fs-6 fw-bolder mb-3'>
                      Button Link
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='urlLink'
                      name='urlLink'
                      placeholder='Enter Link '
                      value={formik.values.urlLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className='col-lg-12 mb-4 '>
                  <div className='fv-row mb-0'>
                    <label htmlFor='description' className='form-label fs-6 fw-bolder mb-3'>
                      Description
                    </label>
                    <textarea
                      placeholder='Enter Description'
                      className='form-control form-control-lg form-control-solid'
                      id='description'
                      name='description'
                      autoComplete='off'
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>

                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.description}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <button
                  disabled={loading}
                  id='kt_signin_submit'
                  type='submit'
                  className='btn btn-primary  me-2 px-6'
                >
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </form>

            <div className='separator separator-dashed my-6'></div>
          </div>
        </div>
      </div>
      <div>
        <SliderDetails sliderData={sliderData} onEdit={handleEdit} refetch={refetch} />
      </div>
      <ToastContainer />
    </>
  )
}

export {MainSliderForm}
