/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PostCategoryListWrapper} from '../../modules/post-category/PostCategoryList'

const postCategoriesBreadcrum: Array<PageLink> = [
  {
    title: 'Post Category list',
    path: 'post-categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PostCategoryWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={postCategoriesBreadcrum}>Post Category list</PageTitle>
      <PostCategoryListWrapper />
    </>
  )
}

export {PostCategoryWrapper}
