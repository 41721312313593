import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getFaqQnaById} from '../core/_requests'
import {FaqQnaEditModalForm} from './FaqQnaEditModalForm'

const AddFaqEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: faqQna,
    error,
  } = useQuery(
    `${QUERIES.FAQ_QNA_URL}-${itemIdForUpdate}`,
    () => {
      return getFaqQnaById(itemIdForUpdate) // Assuming getFaqQnaById is an asynchronous function
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <FaqQnaEditModalForm isUserLoading={isLoading} faqQna={{id: undefined}} />
  }

  if (!isLoading && !error && faqQna) {
    return <FaqQnaEditModalForm isUserLoading={isLoading} faqQna={faqQna} />
  }

  return null
}

export {AddFaqEditModalFormWrapper}
