import React, {useEffect, useState, useCallback} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {QUERIES, isNotEmpty} from '../../../_metronic/helpers'

import {addOfferContent, updateOfferContent} from './core/_requests'

const validationSchema = Yup.object().shape({
  description: Yup.string().required(' Description is required'),
})
type Data = {
  id?: string
  description?: string
}
type props = {
  contentData: Data
  isLoading: Boolean
}
const OfferContent: React.FC<props> = ({isLoading, contentData}) => {
  const [loading, setLoading] = useState(false)
  const [contactFormEdit] = useState(contentData)
  const formik = useFormik({
    initialValues: contactFormEdit,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (isNotEmpty(values.id)) {
          const {_id, __v, updatedAt, createdAt, ...restData} = values as any
          await updateOfferContent(values)
        } else {
          await addOfferContent(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        id='offer_content_change_email'
        className='form'
        noValidate
      >
        <div className='row'>
          <div className='col-lg-12 mb-4 '>
            <div className='fv-row mb-0'>
              <label htmlFor='description' className='form-label fs-6 fw-bolder mb-3'>
                Description
              </label>
              <textarea
                placeholder='Enter Description'
                className='form-control form-control-lg form-control-solid'
                id='description'
                name='description'
                autoComplete='off'
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>

              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.description}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='d-flex'>
          <button
            disabled={loading}
            id='kt_signin_submit'
            type='submit'
            className='btn btn-primary  me-2 px-6'
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {OfferContent}
