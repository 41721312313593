import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {initialBusiness, Business} from '../../core/_models'
import clsx from 'clsx'
import {useListView} from '../../core/ListViewProvider'
import {createBusiness, updateBusiness} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {Loading} from '../../../../data-table/components/loading/Loading'
import useBusinessPageInfo from '../../../../../../utils/useBusinessPageInfo'
import {useBusinesses} from '../../../../../../_metronic/layout/components/sidebar/sidebar-menu/menuData'
import {
  convertFileToBase64,
  extractVideoValues,
  formatVideos,
  handleKeyPress,
  reversePhoneNumber,
} from '../../../../../../utils/helperFn'
import Input, {formatPhoneNumber} from 'react-phone-number-input/input'
import _ from 'lodash'
import DynamicVideoInput from '../../../../real-estate/real-estate-edit/DynamicVideoInput'
import {BusinessImageDropbox} from './BusinessImageDropbox'
import TagInput from '../../../../offers/offer-edit/TagInput'
import toast, {Toaster} from 'react-hot-toast'
type Props = {
  isLoading: boolean
  business: Business
}

const editBusinessSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  zipCode: Yup.string()
    .required('Zip Code is required')
    .matches(/^[0-9]+$/, 'Zip Code must contain only digits')
    .length(5, 'Zip Code must be exactly 5 digits'),
  address: Yup.string().required('Address is required'),
})

const BusinessEditForm: FC<Props> = ({business, isLoading}) => {
  // const {data, error, isLoading: loading} = useBusinesses()
  const {data, error, isLoading: loading} = useBusinesses()
  const {businessId} = useBusinessPageInfo()
  const [eCommerceEnabled, setECommerceEnabled] = useState('No')
  const [videos, setVideos] = useState<any[]>([{value: ''}])
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [businessForEdit] = useState<Business>({
    ...initialBusiness,
    ...business,
    phone: business.phone ? reversePhoneNumber(business.phone) : business.phone,
    amenities: business.amenities ? business.amenities : [],
    isPromoted: !!business.isPromoted,
  })
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const selectedImagePlaceholder = toAbsoluteUrl(`/media/${businessForEdit.avatar}`)
  const [selectedImage, setSelectedImage] = useState<string | null>(selectedImagePlaceholder)
  const [selectedImageTH, setSelectedImageTH] = useState<string | null>(selectedImagePlaceholder)
  const [selectedCategory, setSelectedCategory] = useState(
    business.category ? business.category : businessId
  )

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  useEffect(() => {
    if (!_.isEmpty(businessForEdit)) {
      if (!_.isArray(businessForEdit.videos)) {
        setVideos([{value: ''}])
      } else {
        if (businessForEdit.videos && businessForEdit.videos.length) {
          const formattedVideos = formatVideos(businessForEdit.videos)
          setVideos([...formattedVideos])
        }
      }
    }
  }, [businessForEdit])
  const handleECommerceEnabledChange = (value) => {
    const isChecked = value ? 'Yes' : 'No'
    setECommerceEnabled((preVal) => (preVal = isChecked))
    formik.setFieldValue('eCommerceEnabled', isChecked)
  }
  const updateData = (dataValue) => {
    setVideos([...dataValue])
    const formattedVideos = extractVideoValues(dataValue)
    formik.setFieldValue('videos', formattedVideos)
  }
  const [tags, setTags] = useState<string[]>(
    businessForEdit.amenities ? businessForEdit.amenities : []
  )

  const formik = useFormik({
    initialValues: businessForEdit,
    validationSchema: editBusinessSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        values.amenities = tags
        if (isNotEmpty(values.id)) {
          const {
            updatedAt,
            createdAt,
            url,
            owner,
            offers,
            reviews,
            products,
            totalReviews,
            averageRating,
            averageUserRating,
            ...restData
          } = values as any
          const updatedData = {
            ...restData,
            category: selectedCategory,
            phone: restData.phone ? formatPhoneNumber(restData.phone) : restData.phone,
          }
          await updateBusiness(updatedData)
          cancel(true)
        } else {
          const {...valuess} = values
          const offerData = {
            ...valuess,
            eCommerceEnabled: valuess.eCommerceEnabled,
            avatar: businessForEdit.avatar,
            category: selectedCategory,
            phone: valuess.phone ? formatPhoneNumber(valuess.phone) : valuess.phone,
          }
          await createBusiness(offerData)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const businessCategoryOptions = data?.data?.map((item) => {
    if (item.status === 'Active') {
      return {
        id: item.id,
        name: item.name,
      }
    }
  })
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = e.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      const image = new Image()
      image.src = imageUrl
      image.onload = async () => {
        const {width, height} = image
        if (type === 'thumbnail') {
          if (width !== 800 || height !== 800) {
            URL.revokeObjectURL(imageUrl)
            return toast.error('Thumbnail must be 800x800px')
          }
          const base64 = await convertFileToBase64(file)
          businessForEdit.thumbnail = base64
          formik.setFieldValue('thumbnail', base64)
          setSelectedImageTH(imageUrl)
        }
        if (type === 'avatar') {
          if (width !== 800 || height !== 800) {
            URL.revokeObjectURL(imageUrl)
            return toast.error('Avatar must be 800x800px')
          }
          const base64 = await convertFileToBase64(file)
          setSelectedImage(imageUrl)
          businessForEdit.avatar = base64
          formik.setFieldValue('avatar', base64)
        }
      }
      image.onerror = () => {
        URL.revokeObjectURL(imageUrl)
        toast.error('Failed to load image')
      }
    }
  }
  const updateFiles = (dataFilesValues) => {
    businessForEdit.images = dataFilesValues
    formik.setFieldValue('images', dataFilesValues)
  }
  const handleImageRemove = (type: string) => {
    setSelectedImage(null)
    if (type === 'thumbnail') {
      businessForEdit.thumbnail = ''
    }
    if (type === 'avatar') {
      businessForEdit.avatar = ''
    }
  }
  if (loading) {
    return <Loading />
  }
  const handleTagChange = (newTags) => {
    const stringTags = newTags.map((tag) => tag.toString())
    setTags(stringTags)
    formik.setFieldValue('amenities', stringTags)
  }
  const handleisPromotedChange = (isChecked) => {
    formik.setFieldValue('isPromoted', isChecked)
  }
  return (
    <>
      <Toaster />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row p-0'>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7 text-center'>
                {/* begin::Label */}
                <label className='d-block fw-bold fs-6 mb-5'>Business Logo </label>
                {/* end::Label */}

                {/* begin::Image input */}
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url('${blankImg}')`}}
                >
                  {/* begin::Preview existing avatar */}{' '}
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: `url('${
                        businessForEdit?.avatar ? businessForEdit?.avatar : selectedImage
                      }')`,
                    }}
                  ></div>
                  {/* end::Preview existing avatar */}
                  {/* begin::Label */}
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      type='file'
                      onChange={(e) => handleImageChange(e, 'avatar')}
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                    />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                    onClick={() => handleImageRemove('avatar')}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                    onClick={() => handleImageRemove('avatar')}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                </div>
                <div className='form-text'>
                  Allowed file types: png, jpg, jpeg.Dimension 800x800px
                </div>
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7 text-center'>
                <label className='d-block fw-bold fs-6 mb-5'>Business Thumbnail</label>

                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url('${blankImg}')`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: `url('${
                        businessForEdit?.thumbnail ? businessForEdit?.thumbnail : selectedImageTH
                      }')`,
                    }}
                  ></div>

                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change Thumbnail'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      type='file'
                      onChange={(e) => handleImageChange(e, 'thumbnail')}
                      name='thumbnail'
                      accept='.png, .jpg, .jpeg'
                    />
                    <input type='hidden' name='thumbnail_remove' />
                  </label>

                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel Thumbnail'
                    onClick={() => handleImageRemove('thumbnail')}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>

                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove Thumbnail'
                    onClick={() => handleImageRemove('thumbnail')}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                </div>

                <div className='form-text'>
                  Allowed file types: png, jpg, jpeg. Dimension 800x800px
                </div>
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Name</label>

                <input
                  placeholder='Business Name'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  // disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'>Email Id</label>

                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  type='email'
                  name='email'
                  autoComplete='off'
                  // disabled={formik.isSubmitting || isLoading}
                />
              </div>
              {/* end::Input group */}
            </div>

            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className=' fw-bold fs-6 mb-2'>Contact No</label>

                <Input
                  country='US'
                  {...formik.getFieldProps('phone')}
                  type='text'
                  name='phone'
                  autoComplete='off'
                  maxLength='14'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  placeholder='Contact No'
                  onChange={(value) => {
                    formik.setFieldValue('phone', value)
                  }}
                />

                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'>Website Url</label>
                <input
                  placeholder='Website Name'
                  {...formik.getFieldProps('website')}
                  type='text'
                  name='website'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                  // disabled={formik.isSubmitting || isLoading}
                />
                <small className='text-muted'>https://www.example.com</small>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'>E-commerce Enabled</label>
                <div className='d-flex align-items-center radio_btn_wrap'>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='flexSwitchCheckDefault'
                      // checked={!!formik.values.eCommerceEnabled}
                      checked={formik.values.eCommerceEnabled == 'Yes'}
                      // disabled={formik.isSubmitting || isLoading}
                      name='eCommerceEnabled'
                      onChange={(event) => handleECommerceEnabledChange(event.target.checked)}
                    />
                    <label className='htmlForm-check-label' htmlFor='flexSwitchCheckDefault'>
                      {formik.values.eCommerceEnabled == 'Yes' ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'> Promoted</label>
                <div className='d-flex align-items-center radio_btn_wrap'>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='flexSwitchCheckDefault'
                      checked={!!formik.values.isPromoted}
                      // disabled={formik.isSubmitting || isLoading}
                      name='isPromoted'
                      onChange={(event) => handleisPromotedChange(event.target.checked)}
                    />
                    <label className='htmlForm-check-label' htmlFor='flexSwitchCheckDefault'>
                      {formik.values.isPromoted ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='mb-7 fv-row  '>
                {/* begin::Label */}
                <label className='  fw-bold fs-6 mb-4'>Business Mode</label>
                {/* end::Label */}
                {/* begin::Roles */}
                <div className='d-flex fv-row align-items-center justify-content-between '>
                  {/* begin::Input row */}
                  <div className='d-flex fv-row me-10'>
                    {/* begin::Radio */}
                    <div className='form-check form-check-custom form-check-solid'>
                      {/* begin::Input */}
                      <input
                        className='form-check-input me-3'
                        {...formik.getFieldProps('businessMode')}
                        name='businessMode'
                        type='radio'
                        value='Offline'
                        id='kt_modal_update_role_option_0'
                        checked={formik.values.businessMode === 'Offline'}
                        // disabled={formik.isSubmitting || isLoading}
                      />

                      {/* end::Input */}
                      {/* begin::Label */}
                      <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                        <div className='fw-bolder text-gray-800'>Offline</div>
                      </label>
                      {/* end::Label */}
                    </div>
                    {/* end::Radio */}
                  </div>
                  {/* end::Input row */}
                  {/* begin::Input row */}
                  <div className='d-flex fv-row'>
                    {/* begin::Radio */}
                    <div className='form-check form-check-custom form-check-solid'>
                      {/* begin::Input */}
                      <input
                        className='form-check-input me-3'
                        {...formik.getFieldProps('businessMode')}
                        name='businessMode'
                        type='radio'
                        value='Online'
                        id='kt_modal_update_role_option_0'
                        checked={formik.values.businessMode === 'Online'}
                        // disabled={formik.isSubmitting || isLoading}
                      />

                      {/* end::Input */}
                      {/* begin::Label */}
                      <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                        <div className='fw-bolder text-gray-800'>Online</div>
                      </label>
                      {/* end::Label */}
                    </div>
                    {/* end::Radio */}
                  </div>
                  {/* end::Input row */}
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('businessMode')}
                      name='businessMode'
                      type='radio'
                      value='Hybrid'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.businessMode === 'Hybrid'}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>Hybrid</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
              </div>
            </div>
            {business.id ? (
              <div className='col-lg-6 '>
                <div className='fv-row mb-7'>
                  <label className=' fw-bold fs-6 mb-2'>Select Business Category</label>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formik.getFieldProps('category')}
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value=''>Select Business Category</option>
                    {businessCategoryOptions.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.category && formik.errors.category && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.category}</span>
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            <div className='col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'>Add Amenities</label>

                <div>
                  <TagInput tags={tags} onChange={handleTagChange} />
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'>Description</label>
                <textarea
                  placeholder='Description'
                  rows={3}
                  {...formik.getFieldProps('description')}
                  name='description'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='fv-row mb-7'>
                <label className='  fw-bold fs-6 mb-2'>Add Video URLS</label>
                <DynamicVideoInput arrData={videos} updateData={updateData} />
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className=' fw-bold fs-6 mb-2'>Upload Images</label>
                <BusinessImageDropbox
                  validHeight={1080}
                  validWidth={1920}
                  enableValidation={true}
                  imagesArrayList={businessForEdit.images ? businessForEdit.images : []}
                  updatedImageFiles={updateFiles}
                />
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Address</label>
                <input
                  placeholder='Address'
                  {...formik.getFieldProps('address')}
                  type='text'
                  name='address'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.address}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className=' fw-bold fs-6 mb-2'>City</label>
                <input
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                  type='text'
                  name='city'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className=' fw-bold fs-6 mb-2'>State</label>
                <input
                  placeholder='State'
                  {...formik.getFieldProps('state')}
                  type='text'
                  name='state'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
              </div>
            </div>

            <div className='col-lg-6 '>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Zip Code</label>
                <input
                  placeholder='Zip Code'
                  {...formik.getFieldProps('zipCode')}
                  onKeyPress={handleKeyPress}
                  type='text'
                  name='zipCode'
                  // className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.zipCode && formik.errors.zipCode},
                    {
                      'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
                    }
                  )}
                  autoComplete='off'
                  maxLength={5}
                  // disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.zipCode && formik.errors.zipCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.zipCode}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-6'>
              {business.id ? (
                <div className='mb-7 fv-row  '>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-4'>Status</label>
                  {/* end::Label */}
                  {/* begin::Roles */}
                  <div className='d-flex fv-row align-items-center  '>
                    {/* begin::Input row */}
                    <div className='d-flex fv-row me-10'>
                      {/* begin::Radio */}
                      <div className='form-check form-check-custom form-check-solid'>
                        {/* begin::Input */}
                        <input
                          className='form-check-input me-3'
                          {...formik.getFieldProps('status')}
                          name='status'
                          type='radio'
                          value='Active'
                          id='kt_modal_update_role_option_0'
                          checked={formik.values.status === 'Active'}
                          // disabled={formik.isSubmitting || isLoading}
                        />

                        {/* end::Input */}
                        {/* begin::Label */}
                        <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                          <div className='fw-bolder text-gray-800'>Active</div>
                        </label>
                        {/* end::Label */}
                      </div>
                      {/* end::Radio */}
                    </div>
                    {/* end::Input row */}
                    {/* begin::Input row */}
                    <div className='d-flex fv-row'>
                      {/* begin::Radio */}
                      <div className='form-check form-check-custom form-check-solid'>
                        {/* begin::Input */}
                        <input
                          className='form-check-input me-3'
                          {...formik.getFieldProps('status')}
                          name='status'
                          type='radio'
                          value='Inactive'
                          id='kt_modal_update_role_option_0'
                          checked={formik.values.status === 'Inactive'}
                          // disabled={formik.isSubmitting || isLoading}
                        />

                        {/* end::Input */}
                        {/* begin::Label */}
                        <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                          <div className='fw-bolder text-gray-800'>Inactive</div>
                        </label>
                        {/* end::Label */}
                      </div>
                      {/* end::Radio */}
                    </div>
                    {/* end::Input row */}
                  </div>
                  {/* end::Roles */}
                </div>
              ) : null}
            </div>
          </div>

          {business.id ? (
            <div className='mb-7 d-flex fv-row align-items-center '>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 me-10'>Approval Status</label>
              {/* end::Label */}
              {/* begin::Roles */}
              <div className='d-flex fv-row align-items-center justify-content-around '>
                {/* begin::Input row */}
                <div className='d-flex fv-row me-10'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('approvalStatus')}
                      name='approvalStatus'
                      type='radio'
                      value='Pending'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.approvalStatus === 'Pending'}
                      // disabled={formik.isSubmitting || isLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>Pending</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                {/* end::Input row */}
                {/* begin::Input row */}
                <div className='d-flex fv-row me-10'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('approvalStatus')}
                      name='approvalStatus'
                      type='radio'
                      value='Verified'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.approvalStatus === 'Verified'}
                      // disabled={formik.isSubmitting || isLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>Verify</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                {/* end::Input row */}
                {/* begin::Input row */}
                <div className='d-flex fv-row '>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('approvalStatus')}
                      name='approvalStatus'
                      type='radio'
                      value='Rejected'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.approvalStatus === 'Rejected'}
                      // disabled={formik.isSubmitting || isLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>Reject</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                {/* end::Input row */}
              </div>
              {/* end::Roles */}
            </div>
          ) : null}

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}
        <label className=' lbl_styl'>
          Fields marked with s<span className='required'></span> are mandatory
        </label>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            // disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            // disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {BusinessEditForm}
