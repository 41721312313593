import {FC} from 'react'
import millify from 'millify'

import {MdEvent, MdOutlineHome} from 'react-icons/md'
interface DashBoardDataProps {
  title: string
  icon: React.ReactNode
  value: number
  description: string
  monthValue: number
}
const DashBoardData: FC<DashBoardDataProps> = ({title, icon, value, monthValue, description}) => {
  return (
    <>
      <div className='card p-4' style={{padding: '0'}}>
        <div className='d-flex align-item-center justify-content-between pb-2'>
          <div className='card-title m-0'>
            <div className='fs-3 fw-bold text-gray-900'>{title}</div>
          </div>
          <div className='m-0'>
            <span className='badge badge-light-dark fw-bold me-auto px-4 py-3 size: fs-3'>
              {icon}
            </span>
          </div>
        </div>

        <div className='fs-3 fw-bold text-gray-900 pt-0'>
          <h1 className='mb-0'>{millify(value)}</h1>
          <div className='d-flex align-items-center justify-content-between p-1'>
            <div className='text-gray-500 fw-semibold fs-5 mt-1'>{description}</div>
            <h1 className='mb-0'>
              {title === 'Total Sales' ? `$ ${millify(monthValue)}` : millify(monthValue)}
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashBoardData}
