// @ts-nocheck
import {Column} from 'react-table'
import {InfoCell} from './InfoCell'
import {DateCell} from './DateCell'
import {StatusCell} from './StatusCell'
import {ActionsCell} from './ActionsCell'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {SelectionHeader} from './SelectionHeader'
import {User} from '../../core/_models'
import {format} from 'date-fns'

const offerColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => (
      <InfoCell id={props.data[props.row.index].id} offer={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => <StatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Created Date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => {
      const dateString: String = props.data[props.row.index].createdAt
      const createdDate = format(new Date(dateString), 'MMM dd, yyyy')
      return <DateCell date={createdDate} />
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ActionsCell
        id={props.data[props.row.index].id}
        isDeleted={!!props.data[props.row.index]?.deletedAt}
      />
    ),
  },
]

export {offerColumns}
