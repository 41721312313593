import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../../../../apps/business-management/core/ListViewProvider'
import {useRatingModal} from './RatingContext'

const RatingEditModalHeader = () => {
  const {setItemIdForUpdate} = useListView()
  const {toggleModal} = useRatingModal()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Update Average Rating</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => toggleModal(false)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {RatingEditModalHeader}
