import {KTCard} from '../../../_metronic/helpers'
import {ListHeader} from './components/header/ListHeader'

import {NewsCategoryTable} from './NewsCategoryTable'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {NewsCategoryEditModal} from './blog-category-add-edit/NewsCategoryEditModal'

const NewsCategoryList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <NewsCategoryTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <NewsCategoryEditModal />}
    </>
  )
}

const NewsCategoryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NewsCategoryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {NewsCategoryListWrapper}
