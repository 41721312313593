import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {HeaderNotifications} from '../../../partials/notifications/HeaderNotifications'
import {useQuery} from 'react-query'
import {
  clearAllNotification,
  getAllNotification,
} from '../../../../app/modules/management/core/_requests'
import {Loading} from '../../../../app/modules/offers types/components/loading/Loading'
import {useMemo} from 'react'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'
const btnIconClass1 = 'your-custom-icon-class'
const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()

  const {
    isLoading,
    refetch,
    data: response,
  } = useQuery(
    `notifications`,
    () => {
      return getAllNotification()
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
    }
  )
  const allNotification = response?.length

  const getAllTypes = useMemo(() => {
    return response?.map((item) => item.type) || []
  }, [response])

  const getNewNotification = async () => {
    await refetch()
  }
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          onClick={getNewNotification}
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              className={clsx('btn btn-icon btn-active-color-primary w-35px h-35px ', btnClass)}
              id='kt_drawer_chat_toggle'
            >
              <KTIcon iconName='notification-bing' className={btnIconClass} />
              {allNotification > 0 && (
                <span className=' badge badge1 menu-badge badge-light-danger  '>
                  {allNotification}
                </span>
              )}
            </div>
          </div>
        </div>
        <HeaderNotifications data={response} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser?.avatar ? currentUser?.avatar : blankImg} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
