import React, {ChangeEvent, FC, ReactNode} from 'react'
import {FaTrash} from 'react-icons/fa'
import {FaMinus, FaPlus} from 'react-icons/fa6'
// import style from '../../components/user-profile/profile.module.css'
import clsx from 'clsx'

const DynamicVideoInput = ({arrData, updateData}) => {
  const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const values = [...arrData]
    values[index].value = event.target.value
    updateData(values)
  }

  const handleBlur = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const values = [...arrData]
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
    values[index].hasError = !urlRegex.test(event.target.value)
    updateData(values)
  }

  const handleAddFields = () => {
    updateData([...arrData, {value: '', hasError: false}])
  }

  const handleRemoveFields = (index: number) => {
    if (arrData.length > 1) {
      const values = [...arrData]
      values.splice(index, 1)
      updateData(values)
    }
  }

  return (
    <div className='upload_video_wrap'>
      {arrData.map((field, index) => (
        <div key={index} className={`d-flex align-items-center mb-2`}>
          <input
            type='url'
            autoComplete='off'
            placeholder='Enter URL'
            value={field.value}
            className={`form-control ${field.hasError}`}
            style={{width: 'calc(100% - 54px)'}}
            onChange={(event) => handleInputChange(index, event)}
            onBlur={(event) => handleBlur(index, event)}
          />
          {index !== arrData.length - 1 && (
            <button className='btn' type='button' onClick={() => handleRemoveFields(index)}>
              <FaTrash color='red' />
            </button>
          )}
        </div>
      ))}

      <button
        className='btn align-item-center btn-primary mt-2'
        type='button'
        onClick={handleAddFields}
      >
        <FaPlus className='me-3' />
        Add
      </button>
    </div>
  )
}

export default DynamicVideoInput
