import {KTCard} from '../../../_metronic/helpers'
import {ListHeader} from './components/header/ListHeader'

import {OffersTypesTable} from './OffersTypesTable'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {OfferTypeEditModal} from './offer-edit/OfferTypeEditModal'

const OfferTypesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <OffersTypesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <OfferTypeEditModal />}
    </>
  )
}

const OffersTypesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OfferTypesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OffersTypesListWrapper}
