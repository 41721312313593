/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'
import {FaqQna} from '../../core/_models'
type Props = {
  faqQna: FaqQna
}

const InfoCell: FC<Props> = ({faqQna}) => {
  const navigate = useNavigate()
  // const viewAllBusinessHandler = () => {
  //   navigate(`/faqs/${faqQna.id}/${faqQna.url}`)
  // }
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a
          // onClick={viewAllBusinessHandler}
          className='text-gray-800 text-hover-primary mb-1 pointer'
        >
          {faqQna?.question}
        </a>
      </div>
    </div>
  )
}

export {InfoCell}
