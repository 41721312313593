import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {BusinessListWrapper} from './BusinessListWrapper'
import _ from 'lodash'
import useBusinessPageInfo from '../../../../../utils/useBusinessPageInfo'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import {BusinessDetail} from '../components/business-add-edit/BusinessDetail'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Business List',
    path: '/businesses/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BusinessListPage = () => {
  const {page, businessId, capitalizePage} = useBusinessPageInfo()

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{page} list</PageTitle>
            <Outlet />
          </>
        }
      >
        <Route
          path={`/`}
          element={
            <>
              <BusinessListWrapper />
            </>
          }
        />
        <Route
          path={`/detail`}
          element={
            <>
              <BusinessDetail />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default BusinessListPage
