import React, {useEffect, useState, useCallback} from 'react'
import {ContactForm} from './ContactForm'
import {useQuery} from 'react-query'
import {getContactDetails} from './core/_requests'
import {isEmpty} from 'lodash'
import {Loading} from '../offers types/components/loading/Loading'

const ContactFormWrapper: React.FC = () => {
  const [contactId, setContactId] = useState(undefined)
  const {
    isLoading,
    data: contactData,
    error,
  } = useQuery(
    `contact_details`,
    () => {
      return getContactDetails()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        setContactId(undefined)
        console.error(err)
      },
    }
  )
  if (isLoading) return <Loading />
  if (isEmpty(contactData)) {
    return <ContactForm isLoading={isLoading} contactData={{id: undefined}} />
  }
  if (!isLoading && !error && contactData) {
    return <ContactForm isLoading={isLoading} contactData={contactData} />
  }

  return null
}

export {ContactFormWrapper}
