import React from 'react'
import {isEmpty} from 'lodash'
import ReactPlayer from 'react-player'

const OneVideo = ({videos}) => {
  if (isEmpty(videos)) return null

  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>Videos</h3>
      </div>
      <div className='card-body pt-3 row'>
        {videos.length ? (
          videos.map((video, index) => (
            <div key={index} className='mb-5 col-lg-6 '>
              <ReactPlayer
                className='embed-responsive-item rounded h-300px w-100'
                url={video.value}
                controls={true}
                width='100%'
                height='300px'
                allowFullScreen={true}
              />
            </div>
          ))
        ) : (
          <p>Not Available</p>
        )}
      </div>
    </div>
  )
}

export {OneVideo}
