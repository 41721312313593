import {useQueryClient, useMutation} from 'react-query'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedBusinessComments} from '../../core/_requests'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {useSearchParams} from 'react-router-dom'

const ListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const [searchParams, setSearchParams] = useSearchParams()
  const Id = searchParams.get('Id')
  const businessID = Id ? Id : ''
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(
    () => deleteSelectedBusinessComments(businessID, selected),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.BUSINESS_COMMENTS}-${query}`])
        clearSelected()
      },
    }
  )

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button>
    </div>
  )
}

export {ListGrouping}
