import {useQuery} from 'react-query'
import {ServiceEditModalForm} from './ServiceEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getServiceById} from '../core/_requests'
import {useState} from 'react'
import {useQueryRequest} from '../core/QueryRequestProvider'

const ServiceEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {state} = useQueryRequest()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: service,
    error,
  } = useQuery(
    `${QUERIES.SERVICES_LIST}-${itemIdForUpdate}`,
    () => {
      const service = getServiceById(itemIdForUpdate)
      return service
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <ServiceEditModalForm isLoading={isLoading} service={{id: undefined}} />
  }
  if (!isLoading && !error && service) {
    return <ServiceEditModalForm isLoading={isLoading} service={service} />
  }

  return null
}

export {ServiceEditModalFormWrapper}
