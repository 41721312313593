/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { NewsLatterListWrapper } from '../../modules/news-latter/NewsLatterList'

const NewsLetterPage: FC = () => {
  const offersBreadcrum: Array<PageLink> = [
    {
      title: 'User Inquiry',
      path: '/news-letter',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={offersBreadcrum}>News Letter Subscriber List</PageTitle>
      <NewsLatterListWrapper />
    </>
  )
}

export { NewsLetterPage }
