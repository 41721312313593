/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Plan} from '../../../Plans/core/_models'

type Props = {
  plan: Plan
}

const PlanDetails: FC<Props> = ({plan}) => {
  return (
    <div className='d-flex align-items-center pointer'>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{plan.name}</a>
        {plan.price ? (
          <span className='text-muted fw-bold fs-6'>
            $ {plan.price} / {plan.planType}
          </span>
        ) : null}
      </div>
    </div>
  )
}

export {PlanDetails}
