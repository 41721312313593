import {UpdatePassword} from '../UpdatePassword'
import {useAuth} from '../core/Auth'
import {ProfileUpdate} from './ProfileUpdate'

export function Setting() {
  const {currentUser} = useAuth()
  return (
    <>
      <ProfileUpdate />
      <UpdatePassword />
    </>
  )
}
