/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  offers?: string
  // propertyPosts?: string
  // businesses?: string
}

const PlanDetails: FC<Props> = ({offers}) => (
  <div className='d-flex flex-column '>
    <p className='mb-1 text-sm'>{offers} Offers,</p>
    {/* <p className='mb-1 text-sm'>{businesses} Business</p> */}
  </div>
)

export {PlanDetails}
