import {KTCard} from '../../../_metronic/helpers'
import {ListHeader} from './components/header/ListHeader'
// import {ListViewProvider, useListView} from '../data-table/core/ListViewProvider'

import {ServiceTable} from './ServiceTable'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {ServiceEditModal} from './service-add-edit/ServiceEditModal'

const ServiceList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <ServiceTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ServiceEditModal />}
    </>
  )
}

const ServiceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ServiceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ServiceListWrapper}
