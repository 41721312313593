import {useListView} from '../../core/ListViewProvider'
import { ListToolbar } from './ListToolbar'

import {SearchComponent} from './SearchComponent'

const ListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SearchComponent />
      <div className='card-toolbar'>
      <ListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ListHeader}
