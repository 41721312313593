import {FC, useMemo} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {
  clearAllNotification,
  clearOneNotification,
} from '../../../app/modules/management/core/_requests'
import {useQueryClient, useMutation} from 'react-query'

type notifications = {
  count: number
  type: string
}

type notificationsProps = notifications[]

const HeaderNotifications: FC<{data?: notificationsProps}> = ({data}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const getAllTypes = useMemo(() => {
    return data ? data.map((item) => item.type) : []
  }, [data])

  const getUrl = (value) => {
    return {
      Jobs: '/job-management/list',
      RealEstates: '/real-estate/list/',
      Business: '/business-categories',
      Offer: '/offers-types',
      Blog: '/blog-categories',
      Post: '/community-corner',
      Inquiries: '/user-enquiries',
    }[value]
  }
  const removeNotification = useMutation(() => clearAllNotification(getAllTypes), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      queryClient.invalidateQueries(`notifications`)
    },
  })
  const removeOneNotification = useMutation((type) => clearOneNotification(type), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      queryClient.invalidateQueries(`notifications`)
    },
  })

  const clearNotification = async () => {
    removeNotification.mutate()
  }

  const markReadHandlar = async (value) => {
    removeOneNotification.mutate(value)
    navigate(`${getUrl(value)}`)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <h6 className='fw-bolder d-flex align-items-center fs-5 mb-0'>Notifications</h6>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      {data && data.length ? (
        <div className=''>
          {data.map((item, index) => (
            <div onClick={() => markReadHandlar(item.type)} key={index} className='menu-item px-5'>
              {/* to={getUrl(item.type)} */}
              <a className='menu-link px-5'>
                {item.type === 'Post' ? 'Community Post' : item.type}
                <span className='menu-badge'>
                  <span className='badge badge-light-primary'>New </span>
                </span>
                <span className=' badge menu-badge badge-light-danger  '>{item.count}</span>
              </a>
            </div>
          ))}
          <div className='separator my-2'></div>
          <div className='menu-item px-5'>
            <a onClick={clearNotification} className='menu-link px-5 text-center'>
              Clear All
            </a>
          </div>
        </div>
      ) : (
        <div className='p-4 text-center text-muted'>No Notifications</div>
      )}
    </div>
  )
}

export {HeaderNotifications}
