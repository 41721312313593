import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {getBusinessById} from '../../core/_requests'
import {BusinessEditForm} from './BusinessEditForm'

const BusinessFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: businessData,
    error,
  } = useQuery(
    `${QUERIES.BUSINESS_LIST}-business-${itemIdForUpdate}`,
    () => {
      return getBusinessById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <BusinessEditForm isLoading={isLoading} business={{id: undefined}} />
  }
  if (!isLoading && !error && businessData) {
    return <BusinessEditForm isLoading={isLoading} business={businessData} />
  }

  return null
}

export {BusinessFormWrapper}
