/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'

import {Dropdown1} from '../../../_metronic/partials'
import {isEmpty} from 'lodash'
import {useAuth} from '../auth'
import {Loading} from '../data-table/components/loading/Loading'
import {Link} from 'react-router-dom'
import {useChangeProfile} from '../../../_metronic/layout/components/sidebar/sidebar-menu/menuData'
import {convertFileToBase64} from '../../../utils/helperFn'
import {updateUserProfilePic} from '../apps/user-management/users-list/core/_requests'

const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
const ProfileHeader: React.FC = () => {
  const location = useLocation()
  const {currentUser, auth, setCurrentUser} = useAuth()
  const [selectedImage, setSelectedImage] = useState<string | null | undefined>(blankImg)

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setSelectedImage(imageUrl)
      const base64 = await convertFileToBase64(file)
      // userForEdit.avatar = base64
      const userProfile = {
        id: currentUser?.id,
        avatar: base64,
      }
      const data = await updateUserProfilePic(currentUser?.id, userProfile)

      if (!isEmpty(data)) {
        setCurrentUser(data)
      }
    }
  }

  const handleImageRemove = () => {
    setSelectedImage(null)
    // avatar = ''
  }

  if (isEmpty(currentUser)) return <Loading />

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-95px symbol-fixed position-relative'>
              <img
                src={currentUser?.avatar ? currentUser?.avatar : blankImg}
                alt='user'
                className='w-125px h-125px'
              />
              <div className='position-absolute translate-middle top-0 start-100 mb-6     '>
                <label htmlFor='imageInput' className='cursor-pointer custom_edit'>
                  <i className='bi bi-pencil'></i>
                </label>
                <input
                  type='file'
                  id='imageInput'
                  accept='image/*'
                  style={{display: 'none'}}
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {currentUser?.fullname}
                  </a>
                  <a href='#'>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {currentUser?.phone}
                  </a>

                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {currentUser?.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/overview' && 'active')
                }
                to='/crafted/pages/profile/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/pages/profile/setting' && 'active')
                }
                to='/crafted/pages/profile/setting'
              >
                Setting
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ProfileHeader}
