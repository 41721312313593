// import {ID, Response} from '../../../../_metronic/helpers'
import {ID, Response} from '../../../../_metronic/helpers'
export type Plan = {
  id?: ID
  name?: string
  videos?: string
  businesses?: string
  offers?: string
  OfferImagesLimit?: string
  OfferVideosLimit?: string
  planType?: string
  status?: string
  price?: string
  images?: string
  propertyPosts?: string
  jobPosts?: string
}

export type PlanQueryResponse = Response<Array<Plan>>
export const initialPlan: Plan = {
  status: "Upcoming"
}
