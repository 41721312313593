/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Service} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
type Props = {
  service: Service
}

const InfoCell: FC<Props> = ({service}) => {
  const navigate = useNavigate()
  const openDetailPageHandler = () => {
    navigate({
      pathname: 'detail',
      search: `?Id=${service.id}`,
    })
  }
  return (
    <div className='d-flex align-items-center pointer'>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{service?.name}</a>
      </div>
    </div>
  )
}

export {InfoCell}
