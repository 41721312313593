/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {EnquiryListWrapper} from '../../modules/user-enquiry/EnquiryList'

const InquiryPage: FC = () => {
  const offersBreadcrum: Array<PageLink> = [
    {
      title: 'User Inquiry',
      path: '/user-enquiries',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={offersBreadcrum}>Inquiry List</PageTitle>
      <EnquiryListWrapper />
    </>
  )
}

export {InquiryPage}
