import {useEffect, useMemo, useState} from 'react'
import {KTIcon, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {FileDownloadModal} from '../../files/FileDownloadModal'
import {Filter} from './Filter'
import {exportEnquiryList} from '../../core/_requests'
import {isEmpty} from 'lodash'
import {useQueryClient, useMutation, useQuery} from 'react-query'
import {useQueryRequest} from '../../core/QueryRequestProvider'
const ListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {state} = useQueryRequest()

  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])
  const {
    isLoading,
    refetch,
    data: response,
  } = useQuery(
    `enquiryExport`,
    () => {
      return exportEnquiryList()
    },
    {
      enabled: false,
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    }
  )
  const exportHandler = async () => {
    try {
      refetch()
    } catch (error) {
      console.log(error)
    }
  }
  const [isModalOpen, setIsModalOpen] = useState<Boolean>(false)

  const openFileModal = () => {
    setIsModalOpen(true)
  }
  const closeFileModal = (val: Boolean) => {
    setIsModalOpen(false)
  }
  return (
    <>
      {isModalOpen && !isEmpty(response) && response.data ? (
        <FileDownloadModal fileURL={response.data} closeFileModal={closeFileModal} />
      ) : null}
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <Filter />

        {/* begin::Export */}
        {!isEmpty(response) && response.data ? (
          <button type='button' onClick={openFileModal} className='btn btn-light-primary me-3'>
            <KTIcon iconName='exit-up' className='fs-2' />
            View File
          </button>
        ) : null}
        {/* end::Export */}
        {/* begin::Export */}
        <button type='button' onClick={exportHandler} className='btn btn-light-primary me-3'>
          <KTIcon iconName='exit-up' className='fs-2' />
          Export
        </button>
        {/* end::Export */}
      </div>
    </>
  )
}

export {ListToolbar}
