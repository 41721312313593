import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {RatingEditModalForm} from './RatingEditModalForm'
import {useListView} from '../../../../apps/business-management/core/ListViewProvider'
import {getBusinessById} from '../../../../apps/business-management/core/_requests'
import {useSearchParams} from 'react-router-dom'

const RatingEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [params, _] = useSearchParams()
  const businessId = params.get('Id') ? params.get('Id') : ''
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: businessData,
    error,
  } = useQuery(
    `business-rating`,
    () => {
      const businessData = getBusinessById(businessId)
      return businessData
    },
    {
      cacheTime: 0,
      // enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!isLoading && !error && businessData) {
    return <RatingEditModalForm isLoading={isLoading} business={businessData} />
  }

  return null
}

export {RatingEditModalFormWrapper}
