import React, {useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../_metronic/helpers'
import {addContactDetails, updateContactDetails} from './core/_requests'
import Input, {formatPhoneNumber} from 'react-phone-number-input/input'
import {handleKeyPress, reversePhoneNumber} from '../../../utils/helperFn'
type contact = {
  id?: string
  primaryEmail?: string
  secondaryEmail?: string
  primaryPhone?: string
  secondaryPhone?: string
  city?: string
  address?: string
  zipCode?: string
  state?: string
}
type props = {
  contactData: contact
  isLoading: Boolean
}
const validationSchema = Yup.object().shape({
  primaryEmail: Yup.string().required('Email is required'),
  primaryPhone: Yup.string().required('Phone is required'),
  city: Yup.string().required(' City is required'),
  state: Yup.string().required(' State is required'),
  // address: Yup.string().required(' Address is required'),
  zipCode: Yup.string().required(' ZipCode is required'),
})

const ContactForm: React.FC<props> = ({contactData, isLoading}) => {
  const [loading, setLoading] = useState(false)
  const [contactFormEdit] = useState({
    ...contactData,
    primaryPhone: contactData.primaryPhone
      ? reversePhoneNumber(contactData.primaryPhone)
      : contactData.primaryPhone,
    secondaryPhone: contactData.secondaryPhone
      ? reversePhoneNumber(contactData.secondaryPhone)
      : contactData.secondaryPhone,
  })
  const formik = useFormik({
    initialValues: {
      ...contactFormEdit,
      city: 'Mason',
      state: 'OH',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const modifiedData = {
        ...values,
        primaryPhone: values.primaryPhone
          ? formatPhoneNumber(values.primaryPhone)
          : values.primaryPhone,
        secondaryPhone: values.secondaryPhone
          ? formatPhoneNumber(values.secondaryPhone)
          : values.secondaryPhone,
      }
      setLoading(true)
      try {
        if (isNotEmpty(modifiedData.id)) {
          await updateContactDetails(modifiedData)
        } else {
          await addContactDetails(modifiedData)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Contact Information</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <form
              onSubmit={formik.handleSubmit}
              id='kt_signin_change_email'
              className='form'
              noValidate
            >
              <div className='row mb-6'>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      Email
                    </label>
                    <input
                      autoComplete='off'
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      name='primaryEmail'
                      placeholder='Enter Email '
                      value={formik.values.primaryEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.primaryEmail && formik.errors.primaryEmail && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.primaryEmail}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      Alternate Email
                    </label>
                    <input
                      autoComplete='off'
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      name='secondaryEmail'
                      placeholder='Enter Alternate Email '
                      value={formik.values.secondaryEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.secondaryEmail && formik.errors.secondaryEmail && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.secondaryEmail}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      Phone Number
                    </label>

                    <Input
                      country='US'
                      {...formik.getFieldProps('primaryPhone')}
                      type='text'
                      name='primaryPhone'
                      autoComplete='off'
                      maxLength='14'
                      value={formik.values.primaryPhone}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Contact No'
                      onChange={(value) => {
                        formik.setFieldValue('primaryPhone', value)
                      }}
                    />

                    {formik.touched.primaryPhone && formik.errors.primaryPhone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.primaryPhone}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      Alternate Phone Number
                    </label>

                    <Input
                      country='US'
                      {...formik.getFieldProps('secondaryPhone')}
                      type='text'
                      name='secondaryPhone'
                      autoComplete='off'
                      maxLength='14'
                      value={formik.values.secondaryPhone}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Contact No'
                      onChange={(value) => {
                        formik.setFieldValue('secondaryPhone', value)
                      }}
                    />
                    {formik.touched.secondaryPhone && formik.errors.secondaryPhone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.secondaryPhone}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      Address
                    </label>
                    <input
                      autoComplete='off'
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='address'
                      placeholder='Enter Address'
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      City
                    </label>
                    <input
                      autoComplete='off'
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='city'
                      readOnly
                      placeholder='Enter City'
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.city}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      State
                    </label>
                    <input
                      autoComplete='off'
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='state'
                      placeholder='Enter State '
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.state && formik.errors.state && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.state}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='footerListing' className='form-label fs-6 fw-bolder mb-3'>
                      ZipCode
                    </label>
                    <input
                      autoComplete='off'
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='zipCode'
                      placeholder='Enter ZipCode'
                      minLength={4}
                      maxLength={5}
                      onKeyPress={handleKeyPress}
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.zipCode && formik.errors.zipCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.zipCode}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <button
                  disabled={loading}
                  id='kt_signin_submit'
                  type='submit'
                  className='btn btn-primary  me-2 px-6'
                >
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </form>

            <div className='separator separator-dashed my-6'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ContactForm}
