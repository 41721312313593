import {useQuery} from 'react-query'

const API_URL = process.env.REACT_APP_API_URL
const fetchBusinesses = async () => {
  const response = await fetch(`${API_URL}/businesses_categories`) // Adjust the URL as needed
  const res = response.json()
  return res
}
const fetchInformationCategory = async () => {
  const response = await fetch(`${API_URL}/information_category`)
  const res = response.json()
  return res
}

export const useBusinesses = () => {
  return useQuery('businesses_categories', fetchBusinesses)
}
export const useInformationCategory = () => {
  return useQuery('information_category', fetchInformationCategory)
}

const fetchOfferTypeList = async () => {
  const response = await fetch(`${API_URL}/offerTypes`)
  const res = response.json()
  return res
}
// const footerContentManaged = async (id) => {
//   const response = await fetch(`${API_URL}/content_setting/general_setting/${id}`)
//   const res = response.json()
//   return res
// }
// const footerPostContentManaged = async (data) => {
//   const response = await fetch(`${API_URL}/content_setting/general_setting`, data)
//   const res = response.json()
//   return res
// }
// export const useContentManaged = () => {
//   return useQuery('footer_update', footerContentManaged)
// }
// export const usePostContentManaged = () => {
//   return useQuery('footer_post', footerPostContentManaged)
// }
const changePfofile = async (id) => {
  const response = await fetch(`${API_URL}/users/${id}/profile_update`)
  const res = response.json()
  return res
}
const fetchBlogCategoryList = async () => {
  const response = await fetch(`${API_URL}/blog_categories`)
  const res = response.json()
  return res
}
const fetchBlogCategoryLists = async () => {
  const response = await fetch(`${API_URL}/news_categories`)
  const res = response.json()
  return res
}

export const useOfferTypes = () => {
  return useQuery('offer-types', fetchOfferTypeList)
}

export const useChangeProfile = () => {
  return useQuery('profile_update', changePfofile)
}
export const useCategories = () => {
  return useQuery('blog-category-list', fetchBlogCategoryList)
}
export const useCategoriess = () => {
  return useQuery('blog-category-list', fetchBlogCategoryLists)
}
