import {useQuery} from 'react-query'
import {useState} from 'react'
import {QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import {Description} from '../../data-table/components/view-pages/Description'
import {useSearchParams} from 'react-router-dom'
import {getServiceById} from '../core/_requests'
import {ServiceDetailPage} from './ServiceDetailPage'

const ServiceDetail = () => {
  const [searchParams, _] = useSearchParams()
  const Id = searchParams.get('Id')
  const enabledQuery: boolean = isNotEmpty(Id)
  const {
    isLoading,
    data: post,
    error,
  } = useQuery(
    `${QUERIES.SERVICES_LIST}-${Id}`,
    () => {
      return getServiceById(Id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <ServiceDetailPage blogInfo={post} />
          {/* {post?.description ? <Description description={post?.description} /> : null} */}
        </div>
      </div>
    </>
  )
}

export {ServiceDetail}
