import {useEffect} from 'react'
import {BusinessCommentEditModalHeader} from './BusinessCommentEditModalHeader'
import {BusinessCommentEditModalFormWrapper} from './BusinessCommentEditModalFormWrapper'

const BusinessCommentEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      > 
        <div className='modal-dialog modal-dialog-centered mw-650px'>
           
          <div className='modal-content'>
            <BusinessCommentEditModalHeader headerTitle='Add Business Comment' />
             
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <BusinessCommentEditModalFormWrapper />
            </div>
         
          </div> 
        </div> 
      </div> 
      <div className='modal-backdrop fade show'></div>
 
    </>
  )
}

export {BusinessCommentEditModal}
