import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  approvalStatus?: string
}

const ApprovalStatusCell: FC<Props> = ({approvalStatus}) => (
  <>
    {approvalStatus && (
      <div
        className={`badge fw-bolder ${
          approvalStatus === 'Pending'
            ? 'badge-light-warning'
            : approvalStatus === 'Rejected'
            ? 'badge-light-danger'
            : 'badge-light-success'
        }`}
      >
        {approvalStatus}
      </div>
    )}
  </>
)
export {ApprovalStatusCell}
