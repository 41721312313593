import React from 'react'

const ServiceDetailPage = ({blogInfo}) => {
  return (
    <div className='card mb-5 mb-xxl-12'>
      {/* <div className='card-header   heder_Styl'> */}

      {/* </div> */}

      <div className='card-body pt-3'>
        <div className='heder_Styl'>
          <img
            className='card-title fw-bold text-dark img_Styl'
            src={blogInfo?.logo}
            alt='News Image'
          />
        </div>
        <div className='col-12 mb-2  '>
          <h3 className='card-title fw-bold text-dark'>{blogInfo?.name}</h3>
        </div>
        <div className='row'>
          <div className='col-10 mb-2  '>
            <div
              dangerouslySetInnerHTML={{
                __html: blogInfo?.description,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ServiceDetailPage}
