import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Payment, PaymentQueryResponse} from './_models'
import { orderBy } from 'lodash';
const API_URL = process.env.REACT_APP_API_URL
const Payment_URL = `${API_URL}/subscription`
const getPayments = (query: string): Promise<PaymentQueryResponse> => {
  return axios
    .get(`${Payment_URL}?${query}`)
    .then(({data}: AxiosResponse) => {
      return data
    })
      

}

const getPaymentById = (id: ID): Promise<Payment | undefined> => {
  return axios
    .get(`${Payment_URL}/${id}`)
    .then(({ data }: AxiosResponse) => {
      return data.data
    })  
}

const createPayment = (realEstate: Payment): Promise<Payment | undefined> => {
  return axios
    .post(Payment_URL, realEstate)
    .then((response: AxiosResponse<Response<Payment>>) => response.data)
    .then((response: Response<Payment>) => response.data)
}

const updatePayment = (realEstate: Payment): Promise<Payment | undefined> => {
  return axios
    .put(`${Payment_URL}/${realEstate.id}`, realEstate)
    .then((response: AxiosResponse<Response<Payment>>) => response.data)
    .then((response: Response<Payment>) => response.data)
}

const deletePayment = (realEstateId: ID): Promise<void> => {
  return axios.delete(`${Payment_URL}/${realEstateId}`).then(() => {})
}

const deleteSelectedPayments = (realEstateIds: Array<ID>): Promise<void> => {
  const requests = realEstateIds.map((id) => axios.delete(`${Payment_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {deletePayment, deleteSelectedPayments, updatePayment, getPaymentById, createPayment, getPayments}
