import {useQuery} from 'react-query'
import {EnquiryEditModalForm} from './EnquiryEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getEnquiryById} from '../core/_requests'

const EnquiryEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: enquiry,
    error,
  } = useQuery(
    `${QUERIES.ENQUIRY_LIST}-${itemIdForUpdate}`,
    () => {
      return getEnquiryById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <EnquiryEditModalForm isLoading={isLoading} enquiry={{id: undefined}} />
  }
  if (!isLoading && !error && enquiry) {
    return <EnquiryEditModalForm isLoading={isLoading} enquiry={enquiry} />
  }

  return null
}

export {EnquiryEditModalFormWrapper}
