import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getBlogCategoryById} from '../core/_requests'
import {BlogCategoryEditModalForm} from './BlogCategoryEditModalForm'

const BlogCategoryEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: blogCategory,
    error,
  } = useQuery(
    `${QUERIES.BLOG_CATEGORY_LIST}-${itemIdForUpdate}`,
    () => {
      const blogCategory = getBlogCategoryById(itemIdForUpdate)
      return blogCategory
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <BlogCategoryEditModalForm isUserLoading={isLoading} blogCategory={{id: undefined}} />
  }
  if (!isLoading && !error && blogCategory) {
    return <BlogCategoryEditModalForm isUserLoading={isLoading} blogCategory={blogCategory} />
  }

  return null
}

export {BlogCategoryEditModalFormWrapper}
