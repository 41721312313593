import React from 'react'
import {Button, Table} from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import {Link, useNavigate} from 'react-router-dom'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {ToastContainer, toast} from 'react-toastify'
import {KTIcon, QUERIES} from '../../../../_metronic/helpers'
import {deleteSlider} from '../core/_requests'

const SocialMediaDetails = ({sliderData, onEdit, refetch}) => {
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const socialMediaData = sliderData

  const editPageHandler = (data) => {
    window.scrollTo(0, 0)
    onEdit(data)
  }

  const deleteItem = useMutation((id) => deleteSlider(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.HDR_QNA_URL}-${query}`])
      toast.success('Slider deleted successfully!', {position: 'top-right'})
      refetch()
    },
  })
  const handleDelete = async (id) => {
    await deleteItem.mutateAsync(id)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Slider Details</h3>
          </div>
        </div>

        <div className='card-body p-5'>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Image</th>
                <th>Title</th>
                <th>Button Name</th>
                <th>Description</th>
                <th>Url</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {socialMediaData &&
                socialMediaData.map((data, index) => (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>
                      <img src={data.image} alt='image' style={{width: '50px', height: '50px'}} />
                    </td>
                    <td>{data.title}</td>
                    <td>{data.buttonName}</td>
                    <td>{data.description}</td>
                    <td>{data.urlLink}</td>
                    <td>
                      <div className='dropdown'>
                        <button
                          className='btn btn-secondary dropdown-toggle'
                          type='button'
                          id='dropdownMenuButton1'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Action
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                          <li>
                            <a className='dropdown-item' onClick={() => editPageHandler(data)}>
                              Edit
                            </a>
                          </li>
                          <li>
                            <a className='dropdown-item' onClick={() => handleDelete(data.id)}>
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default SocialMediaDetails
