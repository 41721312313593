import {createContext, useContext, useState} from 'react'

const initialListView = {
  data: {invalidBusiness: {}, existingBusiness: {}},
  setFailedList: (data) => {},
}
const ListFailedContext = createContext(initialListView)

const ListFailedProvider = ({children}) => {
  const [data, setData] = useState({
    invalidBusiness: {},
    existingBusiness: {},
  })
  const setFailedList = (data) => {
    setData(data)
  }

  return (
    <ListFailedContext.Provider value={{data, setFailedList}}>
      {children}
    </ListFailedContext.Provider>
  )
}

const useListFailedView = () => useContext(ListFailedContext)

export {ListFailedProvider, useListFailedView}
