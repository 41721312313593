import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {PostCategory, PostCategoryQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const POST_CATEGORY_URL = `${API_URL}/community_category`
const getPostCategories = (query?: string): Promise<PostCategoryQueryResponse> => {
  return axios.get(`${POST_CATEGORY_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getPostCategoryById = (id: ID): Promise<PostCategory | undefined> => {
  return axios.get(`${POST_CATEGORY_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...user} = data.data
    const modifiedUser: PostCategory = {id: _id, ...user}
    return modifiedUser
  })
}

const createPostCategory = (postCategory: PostCategory): Promise<PostCategory | undefined> => {
  return axios
    .post(POST_CATEGORY_URL, postCategory)
    .then((response: AxiosResponse<Response<PostCategory>>) => response.data)
    .then((response: Response<PostCategory>) => response.data)
}

const updatePostCategory = (postCategory: PostCategory): Promise<PostCategory | undefined> => {
  return axios
    .put(`${POST_CATEGORY_URL}/${postCategory.id}`, postCategory)
    .then((response: AxiosResponse<Response<PostCategory>>) => response.data)
    .then((response: Response<PostCategory>) => response.data)
}

const deletePostCategory = (postCategoryId: ID): Promise<void> => {
  return axios.delete(`${POST_CATEGORY_URL}/${postCategoryId}`).then(() => {})
}

const deleteSelectedPostCategories = (postCategoryIds: Array<ID>): Promise<void> => {
  const requests = postCategoryIds.map((id) => axios.delete(`${POST_CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restorePostCategory = (postCategoryIds: ID): Promise<void> => {
  return axios.get(`${POST_CATEGORY_URL}/restore/${postCategoryIds}`).then(() => {})
}
const restoreSelectedPostCategories = (postCategoryIds: Array<ID>): Promise<void> => {
  const requests = postCategoryIds.map((id) => axios.get(`${POST_CATEGORY_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}
export {
  deletePostCategory,
  deleteSelectedPostCategories,
  updatePostCategory,
  getPostCategoryById,
  createPostCategory,
  getPostCategories,
  restorePostCategory,
  restoreSelectedPostCategories,
}
