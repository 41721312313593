/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {NewsCategoryListWrapper} from '../../modules/news-category/NewsCategoryList'
const newsCategoriesBreadcrum: Array<PageLink> = [
  {
    title: 'News Management',
    path: 'news-categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const NewsCategoryWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={newsCategoriesBreadcrum}>News Category list</PageTitle>
      <NewsCategoryListWrapper />
    </>
  )
}

export {NewsCategoryWrapper}
