import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Offer, UsersQueryResponse} from './_models'
import {orderBy} from 'lodash'
import {Post} from '../../communityPost/core/_models'
const API_URL = process.env.REACT_APP_API_URL
const OFFER_URL = `${API_URL}/offers`
const getOffers = (offerTypeId: string, query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${OFFER_URL}/admin?offerType=${offerTypeId}&${query}`)
    .then(({data}: AxiosResponse) => {
      return data
    })
}

const getOfferById = (id: ID): Promise<Offer | undefined> => {
  return axios.get(`${OFFER_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...user} = data.data
    const videos = data.data.videos.map((item) => {
      return {value: item, hasError: false}
    })
    const modifiedUser = {
      id: _id,
      ...user,
      videos: videos,
    }

    return modifiedUser
  })
}

const createOffer = (offer: Offer): Promise<Offer | undefined> => {
  return axios
    .post(OFFER_URL, offer)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response.data)
}

const updateOffer = (offer: Offer): Promise<Offer | undefined> => {
  return axios
    .put(`${OFFER_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response.data)
}

const deleteOffer = (offerId: ID): Promise<void> => {
  return axios.delete(`${OFFER_URL}/${offerId}`).then(() => {})
}
const restoreOffer = (offerId: ID): Promise<void> => {
  return axios.get(`${OFFER_URL}/restore/${offerId}`).then(() => {})
}

const removeOfferImage = (offerId: ID, data: any): Promise<void> => {
  return axios.post(`${OFFER_URL}/${offerId}/removeImage`, data).then((response) => response.data)
}

const deleteSelectedOffers = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.delete(`${OFFER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const restoreSelectedOffers = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.get(`${OFFER_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteOffer,
  restoreOffer,
  deleteSelectedOffers,
  updateOffer,
  restoreSelectedOffers,
  removeOfferImage,
  getOfferById,
  createOffer,
  getOffers,
}
