import {KTCard} from '../../../_metronic/helpers'
import {ListHeader} from './components/header/ListHeader'
// import {ListViewProvider, useListView} from '../data-table/core/ListViewProvider'

import {PlanTable} from './PlansTable'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {PlanEditModal} from './plan-add-edit/PlanEditModal'

const PlanList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <PlanTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PlanEditModal />}
    </>
  )
}

const PlanListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PlanList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PlanListWrapper}
