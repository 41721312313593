/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Plan} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
type Props = {
  plan: Plan
}

const InfoCell: FC<Props> = ({plan}) => {
  const navigate = useNavigate()
  const openDetailPageHandler = () => {
    navigate({
      pathname: '#',
      // search: `?Id=${plan.id}`,
    })
  }
  return (
    <div onClick={openDetailPageHandler} className='d-flex align-items-center pointer'>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{plan?.name}</a>
        {plan.price !== '0' ? (
          <span className='text-muted fw-bold fs-6'>
            ${plan.price} /{plan.planType}
          </span>
        ) : (
          <span className='text-muted fw-bold fs-6'>Free</span>
        )}
      </div>
    </div>
  )
}

export {InfoCell}
