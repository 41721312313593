import {useQuery} from 'react-query'
import {QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {ContactInfo} from '../../../data-table/components/view-pages/ContactInfo'
import {Description} from '../../../data-table/components/view-pages/Description'
import {DetailHeader} from '../../../data-table/components/view-pages/DetailHeader'
import {Features} from '../../../data-table/components/view-pages/Features'
import {ImageSection} from '../../../data-table/components/view-pages/ImageSection'
import {ReviewList} from '../../../data-table/components/view-pages/ReviewList'
import {TimeTable} from '../../../data-table/components/view-pages/TimeTable'
import {VideosSection} from '../../../data-table/components/view-pages/VideosSection'
import {useListView} from '../../core/ListViewProvider'
import {getInfoDepartmentById} from '../../core/_requests'
import {useSearchParams} from 'react-router-dom'
import { Loading } from '../../../management/components/loading/Loading'

const InformationDepartmentDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const deptId = searchParams.get('deptId')
  const enabledQuery: boolean = isNotEmpty(deptId)
  const {
    isLoading,
    data: information,
    error,
  } = useQuery(
    `${QUERIES.INFO_DEPARTMENT_LIST}-${deptId}`,
    () => {
      return getInfoDepartmentById(deptId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (isLoading) return <Loading />
  return (
    <>
      <DetailHeader dataInfo={information} />
      <div className='row'>
        <div className='col-lg-8'>
          {information?.description ? <Description description={information?.description} /> : null}
          {information?.images?.length ? <ImageSection images={information?.images} /> : null}
        </div>
        <div className='col-lg-4'>
          <ContactInfo contactInfo={information} socialMedia={false} />
        </div>
      </div>
    </>
  )
}

export {InformationDepartmentDetail}
