import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ShopStatus } from './ShopStatus'
import { AvgRatingReview } from './AvgRatingReview'
import { RatingModalProvider } from './edit-rating/RatingContext'

const DetailHeader = ({ dataInfo, shopStatus = false, avgReviewRating = false }) => {
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            {dataInfo?.avatar ? (
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-95px symbol-fixed position-relative'>
                  <img
                    src={
                      dataInfo?.avatar
                        ? dataInfo?.avatar
                        : toAbsoluteUrl('/media/avatars/300-1.jpg')
                    }
                    alt={dataInfo?.name}
                  />
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>
            ) : null}

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {dataInfo?.name}
                    </a>
                    {dataInfo.approvalStatus === 'Verified' ? (
                      <a href='#'>
                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                      </a>
                    ) : null}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {dataInfo?.address}, {dataInfo?.city}, {dataInfo?.state}, 
                      {dataInfo?.zipCode}
                    </a>
                    {dataInfo?.email ? <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {dataInfo?.email}
                    </a> : null}
                  </div>
                  {shopStatus ? (
                    <div className=' d-flex align-items-center mb-3'>
                      <span className='fw-bolder fs-6 me-2'>E-Commerce Enabled</span>
                      <span className='fw-bolder fs-6 me-2'>
                        <span
                          className={`badge  ${dataInfo.eCommerceEnabled === 'Yes' ? 'badge-success' : 'badge-warning'
                            }`}
                        >
                          {dataInfo.eCommerceEnabled}
                        </span>
                      </span>
                    </div>
                  ) : null}

                  {dataInfo?.averageUserRating && dataInfo?.reviews?.length ? (
                    <AvgRatingReview
                      businessRating={
                        dataInfo?.averageUserRating > dataInfo.averageRating
                          ? dataInfo?.averageUserRating
                          : dataInfo.averageRating
                      }
                      reviews={dataInfo?.reviews}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { DetailHeader }
