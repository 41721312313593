/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteNewsLatter } from '../../core/_requests'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useListView } from '../../core/ListViewProvider'
type Props = {
  id: ID
}

const ActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = useMutation(() => deleteNewsLatter(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.NEWS_LETTER_USERS}-${query}`])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
      </div>
      {/* end::Menu */}
    </>
  )
}

export { ActionsCell }
