import {FC} from 'react'
type Props = {
  date?: number
}

const DateCell: FC<Props> = ({date}) => {
  return <div className=''>{date}</div>
}
// badge badge-light fw-bolder
export {DateCell}
