/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {FaqQnaListWrapper} from '../../modules/faq-cotegory/FaqQnaList'
const faqQnaBreadcrum: Array<PageLink> = [
  {
    title: 'FAQ  list',
    path: 'faqs',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const FaqQnaWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={faqQnaBreadcrum}>FAQ list</PageTitle>
      <FaqQnaListWrapper />
    </>
  )
}

export {FaqQnaWrapper}
