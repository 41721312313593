/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {BlogCategoryListWrapper} from '../../modules/blog-category/BlogCategoryList'

const blogCategoriesBreadcrum: Array<PageLink> = [
  {
    title: 'Blog Management',
    path: 'blog-categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const BlogCategoryWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={blogCategoriesBreadcrum}>Blog Category list</PageTitle>
      <BlogCategoryListWrapper />
    </>
  )
}

export {BlogCategoryWrapper}
