import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {getEmailVerification} from '../../management/core/_requests'
import {QUERIES, isNotEmpty, stringifyRequestQuery} from '../../../../_metronic/helpers'
import {useQueryRequest} from '../../management/core/QueryRequestProvider'
import {useMutation, useQuery} from 'react-query'
import {Loading} from '../../management/components/loading/Loading'
const userData = {
  email: '',
  message: '',
}
export function EmailVarificationSuccess() {
  const navigate = useNavigate()
  const [resData, setResData] = useState(userData)
  const [isSuccess, setisSuccess] = useState(false)
  const [isError, setisError] = useState(false)
  const {
    mutate: validateEmailId,
    isLoading,
    error,
  } = useMutation(getEmailVerification, {
    onSuccess: (data) => {
      setisSuccess(true)

      setResData({...data})
    },
    onError: (error) => {
      console.log('error')
      setisError(true)
    },
  })

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  useEffect(() => {
    if (token) {
      validateEmailId({token: token})
    }
  }, [token])

  return (
    <>
      {isSuccess ? (
        <>
          <div>
            <div className='container '>
              <div>
                <h2 className='text-center pb-3'>Success</h2>
                <h5 className='pb-3 text-center'>
                  Your email address <b>{resData.email}</b> has been successfully verified! Welcome
                  to the community !.
                </h5>
                <div className='d-grid mb-10'>
                  <button
                    onClick={() => navigate('/auth/login')}
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                  >
                    <span className='indicator-label'>Back To login</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : isError ? (
        <div>
          <div className='container '>
            <div>
              <h2 className='text-center pb-3'>Failed !</h2>
              <h5 className='pb-3 text-center'> Email verification failed</h5>
              <div className='d-grid mb-10'>
                <button
                  onClick={() => navigate('/auth/login')}
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                >
                  <span className='indicator-label'>Back To login</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* {error.data.message} */}
    </>
  )
}
