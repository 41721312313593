import { KTCard } from '../../../_metronic/helpers'
import { ListHeader } from './components/header/ListHeader'

import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { NewsLatterTable } from './NewsLatterTable'
// import { NewsLatterEditModal } from './user-enquiry-add-edit/NewsLatterEditModal'

const NewsLatterList = () => {
  // const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <NewsLatterTable />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <NewsLatterEditModal />} */}
    </>
  )
}

const NewsLatterListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NewsLatterList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { NewsLatterListWrapper }
