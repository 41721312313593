import React from 'react'
import {DetailHeader} from '../../data-table/components/view-pages/DetailHeader'
import {Description} from '../../data-table/components/view-pages/Description'
import {Features} from '../../data-table/components/view-pages/Features'
import {ImageSection} from '../../data-table/components/view-pages/ImageSection'
import {VideosSection} from '../../data-table/components/view-pages/VideosSection'
import {ReviewList} from '../../data-table/components/view-pages/ReviewList'
import {QUERIES, isNotEmpty} from '../../../../_metronic/helpers'
import {useSearchParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getOfferById} from '../core/_requests'
import {OfferHeader} from '../../data-table/components/view-pages/OfferHeader'
import {TermsCondition} from '../../data-table/components/view-pages/Terms_Condn'
import {BusinessInfo} from '../../data-table/components/view-pages/BusinessInfo'
import {Loading} from '../components/loading/Loading'
import {OneVideo} from '../../data-table/components/view-pages/OneVideo'
import {ProductSection} from '../../data-table/components/view-pages/edit-rating/ProductSection'
import {OfferProductSection} from './OfferProductSection'

const OfferDetailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const Id = searchParams.get('Id')
  const enabledQuery: boolean = isNotEmpty(Id)
  const {
    isLoading,
    data: offer,
    error,
  } = useQuery(
    `${QUERIES.OFFER_LIST}-${Id}`,
    () => {
      return getOfferById(Id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  if (isLoading) return <Loading />
  return (
    <>
      <OfferHeader offerInfo={offer} />
      {offer?.business ? <BusinessInfo businessInfo={offer?.business} /> : null}
      {offer ? <OfferProductSection products={offer} /> : null}
      {offer?.description ? <Description description={offer?.description} /> : null}
      {offer?.termsAndConditions ? (
        <TermsCondition termsAndConditions={offer?.termsAndConditions} />
      ) : null}
      {offer?.images?.length ? <ImageSection images={offer?.images} /> : null}
      {offer?.videos?.length ? <OneVideo videos={offer?.videos} /> : null}
    </>
  )
}

export {OfferDetailPage}
