import {useEffect, useState} from 'react'
import {ExcelToJsonConverter} from './ExcelToJsonConverter'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {BusinessesFailedTable} from '../../Failed-list-table/BusinessesFailedTable'
import {ListFailedProvider, useListFailedView} from '../../core/ListFailedProvider'
import {FailedRecords} from './FailedRecords'
import _ from 'lodash'
function SampleExcelModal({closeSampleModal, fileName}) {
  const {data, setFailedList} = useListFailedView()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  const downloadSampleExcel = () => {
    const publicFilePath = toAbsoluteUrl(`/files/${fileName}`)
    // Create a download link and trigger the download
    const downloadLink = document.createElement('a')
    downloadLink.href = publicFilePath
    downloadLink.download = fileName
    downloadLink.click()
    closeSampleModal(false)
  }
  const closeModal = () => {
    setFailedList({existingBusiness: {}, invalidBusiness: {}})
    closeSampleModal(false)
  }
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Import Excel</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={closeModal}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}

            <div className='modal-body scroll-y'>
              <div
                className='d-flex justify-content-center cus_btn_wrap '
                data-kt-user-table-toolbar='base'
              >
                <button
                  onClick={downloadSampleExcel}
                  type='button'
                  className='btn btn-light-primary me-3'
                >
                  <KTIcon iconName='exit-down' className='fs-2' />
                  Download Sample Excel
                </button>
                <ExcelToJsonConverter />
              </div>
              {!_.isEmpty(data.existingBusiness) || !_.isEmpty(data.invalidBusiness) ? (
                <FailedRecords />
              ) : null}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {SampleExcelModal}
