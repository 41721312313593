import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PaymentListWrapper} from './PaymentList'

const planBreadcrumbs: Array<PageLink> = [
  {
    title: 'Payment Management',
    path: '/plans/',
    isSeparator: false,
    isActive: false,
  },
]

const PaymentPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={planBreadcrumbs}>Payment list</PageTitle>
      <PaymentListWrapper />
    </>
  )
}

export default PaymentPage
