/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {NewsCategory} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'
type Props = {
  newsCategory: NewsCategory
}

const InfoCell: FC<Props> = ({newsCategory}) => {
  const navigate = useNavigate()
  const viewAllBusinessHandler = () => {
    navigate(`/news/${newsCategory.id}/${newsCategory.slug}`)
  }
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a
          onClick={viewAllBusinessHandler}
          className='text-gray-800 text-hover-primary mb-1 pointer'
        >
          {newsCategory?.name}
        </a>
      </div>
    </div>
  )
}

export {InfoCell}
