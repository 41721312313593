import React from 'react'
import {formatDate, formatDateNew} from '../../../../../utils/helperFn'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
const blankImg = toAbsoluteUrl('/media/misc/PlaceHolder.jpg')
const OfferHeader = ({offerInfo}) => {
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-body p-4'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className=''>
              <div className='main_info_promo'>
                <div className='row'>
                  <div className='col-lg-4 imgOff_wrap'>
                    <img
                      src={offerInfo?.image ? offerInfo?.image : blankImg}
                      alt='Metornic'
                      className='w-300px h-130px'
                    />
                  </div>
                  <div className='col-lg-8'>
                    <div className='promo_r_sec'>
                      <h2 className='fw-bold text-dark'>{offerInfo.title}</h2>

                      <div className='promo_tbl'>
                        <table className='table table-borderless'>
                          <tbody>
                            <tr>
                              <th className='fw-bold text-dark'>
                                <i className='fa-solid fa-bullhorn me-2 fs-1'></i>
                                Promo Code
                              </th>
                              <td>
                                <div className='d-flex flex-wrap'>
                                  <h2 className='fw-bold text-dark'>{offerInfo.couponCode}</h2>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className='fw-bold text-dark'>
                                <i className='fa-solid fa-percent me-2 fs-1'></i>
                                Discount
                              </th>
                              <td>Upto {offerInfo.discountPercentage}% Cashback</td>
                            </tr>
                            <tr>
                              <th className='fw-bold text-dark'>
                                <i className='fa-solid fa-hourglass-half me-2 fs-1'></i>
                                Valid till Date
                              </th>
                              {offerInfo.endDate && <td>{formatDateNew(offerInfo.endDate)}</td>}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {OfferHeader}
