/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Business} from '../../core/_models'
import {useLocation, useNavigate} from 'react-router-dom'

type Props = {
  business: Business
}

const UserInfoCell: FC<Props> = ({business}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const openViewPage = () => {
    // setItemIdForUpdate(id)
    if (location.pathname !== '/businesses/list') {
      navigate({
        pathname: `${location.pathname}/detail`,
        search: `?Id=${business.id}`,
      })
    } else {
      navigate(`/businesses/list/detail?Id=${business.id}`, {
        replace: true,
      })
    }
  }
  return (
    <div onClick={openViewPage} className='d-flex align-items-center pointer'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a>
          <div className='symbol-label'>
            <img
              src={
                business.avatar ? business.avatar : toAbsoluteUrl(`/media/svg/avatars/blank.svg`)
              }
              alt={business.name}
              className='w-100'
            />
          </div>
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{business.name}</a>
        <span>{business.email}</span>
      </div>
    </div>
  )
}

export {UserInfoCell}
