import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Plan, PlanQueryResponse} from './_models'
import {orderBy} from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const Plan_URL = `${API_URL}/plans`
const getPlans = (query: string): Promise<PlanQueryResponse> => {
  return axios.get(`${Plan_URL}/admin?${query}`).then(({data}: AxiosResponse) => {
    // const {data:realEstateData, ...restData} = data;

    // const modifiedArray = realEstateData.map((obj) => {
    //    const { _id,__v,  ...rest } = obj;
    //    return { id: _id, ...rest };
    //   });
    //   const sortedArr = orderBy(modifiedArray, ["createdAt"], ["desc"]);
    //   const realEstateList:PlanQueryResponse = {
    //     data:sortedArr,
    //     // ...restData
    //   }
    return data
    // return realEstateList
  })
}

const getPlanById = (id: ID): Promise<Plan | undefined> => {
  return axios.get(`${Plan_URL}/${id}`).then(({data}: AxiosResponse) => {
    // const { _id,__v, ...user } = data.data;
    // const modifiedUser:Plan = { id: _id, ...user };
    return data.data
  })
}

const createPlan = (realEstate: Plan): Promise<Plan | undefined> => {
  return axios
    .post(Plan_URL, realEstate)
    .then((response: AxiosResponse<Response<Plan>>) => response.data)
    .then((response: Response<Plan>) => response.data)
}

const updatePlan = (realEstate: Plan): Promise<Plan | undefined> => {
  return axios
    .put(`${Plan_URL}/${realEstate.id}`, realEstate)
    .then((response: AxiosResponse<Response<Plan>>) => response.data)
    .then((response: Response<Plan>) => response.data)
}

const deletePlan = (realEstateId: ID): Promise<void> => {
  return axios.delete(`${Plan_URL}/${realEstateId}`).then(() => {})
}
const restorePlans = (realEstateId: ID): Promise<void> => {
  return axios.get(`${Plan_URL}/restore/${realEstateId}`).then(() => {})
}
const deleteSelectedPlans = (realEstateIds: Array<ID>): Promise<void> => {
  const requests = realEstateIds.map((id) => axios.delete(`${Plan_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const restoreSelectedPlans = (realEstateIds: Array<ID>): Promise<void> => {
  const requests = realEstateIds.map((id) => axios.get(`${Plan_URL}/restore/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deletePlan,
  restoreSelectedPlans,
  deleteSelectedPlans,
  updatePlan,
  getPlanById,
  createPlan,
  getPlans,
  restorePlans,
}
