import {useEffect} from 'react'
import {BusinessFormWrapper} from './BusinessFormWrapper'
import {BaseModal} from '../../../../../../components/BaseModal'
import {BusinessEditModalHeader} from './BusinessEditModalHeader'
import {useLocation} from 'react-router-dom'
import _ from 'lodash'
const BusinessEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  const location = useLocation()
  const pageName = location.pathname.split('/').pop()
  const page = _.chain(pageName)
    .split('-')
    .map((word) => {
      const capitalizedWord = _.capitalize(word)
      return word.endsWith('s') ? capitalizedWord.slice(0, -1) : capitalizedWord
    })
    .join(' ')
    .value()

  return (
    <>
      <BaseModal fullSize={true}>
        <BusinessEditModalHeader headerTitle={`Add ${page}`} />

        <div className='modal-body scroll-y'>
          <BusinessFormWrapper />
        </div>
      </BaseModal>
    </>
  )
}

export {BusinessEditModal}
