import {Link, Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {ContentManagement} from './ContentManagement'
import {SocialMediaLinks} from './SocialMediaLinks'
import {FooterCotegeryFormLink} from './FooterCotegeryFormLink'
import {MainSliderForm} from './MainSliderForm'

import {ContactFormWrapper} from './ContactFormWraper'
import {OfferContentWrapper} from './OfferContentWrapper'

const planBreadcrumbs: Array<PageLink> = [
  {
    title: 'Content Management',
    path: '/content-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ManagementHeader = () => {
  // const location = useLocation()
  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <ContentManagement />
              <Outlet />
            </>
          }
        >
          <Route
            path='social-media-link'
            element={
              <>
                <PageTitle breadcrumbs={planBreadcrumbs}>Social Media Links</PageTitle>
                <SocialMediaLinks />
              </>
            }
          />
          <Route
            path='footer-links/*'
            element={
              <>
                <PageTitle breadcrumbs={planBreadcrumbs}>Footer Links</PageTitle>
                <FooterCotegeryFormLink />
              </>
            }
          />
          <Route
            path='slider-form/*'
            element={
              <>
                <PageTitle breadcrumbs={planBreadcrumbs}>Slider Changes</PageTitle>
                <MainSliderForm />
              </>
            }
          />
          {/*  */}
          <Route
            path='contact-info'
            element={
              <>
                <PageTitle breadcrumbs={planBreadcrumbs}>Contact Details</PageTitle>
                <ContactFormWrapper />
              </>
            }
          />
          <Route
            path='promotions-content'
            element={
              <>
                <PageTitle breadcrumbs={planBreadcrumbs}>Promotions Content</PageTitle>
                <OfferContentWrapper />
              </>
            }
          />
          <Route index element={<Navigate to='/content-management/social-media-link' />} />
        </Route>
      </Routes>
    </>
  )
}

export default ManagementHeader
