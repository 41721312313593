import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {InformationCategory, initialCategory} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createInformationCategory, updateInformationCategory} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'
import {convertFileToBase64} from '../../../../utils/helperFn'
import toast, {Toaster} from 'react-hot-toast'

type Props = {
  isUserLoading: boolean
  informationCategory: InformationCategory
}
const editInformationCategorySchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
})

const InformationEditModalForm: FC<Props> = ({informationCategory, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [categoryForEdit] = useState<InformationCategory>({
    ...informationCategory,
    name: informationCategory.name,
    status: informationCategory.status,
    avatar: informationCategory.avatar,
  })
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const selectedImagePlaceholder = toAbsoluteUrl(`/media/${categoryForEdit.avatar}`)
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: editInformationCategorySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const updateValues = {
        ...values,
        avatar: categoryForEdit.avatar,
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateInformationCategory(updateValues)
          cancel(true)
        } else {
          await createInformationCategory(updateValues)

          cancel(true)
        }
        setSelectedImage(toAbsoluteUrl(`/media/${updateValues.avatar || 'svg/avatars/blank.svg'}`))

        cancel(true)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const [selectedImage, setSelectedImage] = useState<string | null>(selectedImagePlaceholder)

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const image = new Image()
      const imageUrl = URL.createObjectURL(file)
      image.src = imageUrl

      image.onload = async () => {
        if (image.width !== 300 || image.height !== 300) {
          URL.revokeObjectURL(imageUrl)
          return toast.error('Please select the image with dimension of 300x300px')
        }
        setSelectedImage(imageUrl)
        const base64 = await convertFileToBase64(file)
        categoryForEdit.avatar = base64
        formik.setFieldValue('avatar', base64)
      }
      image.onerror = () => {
        URL.revokeObjectURL(imageUrl)
        toast.error('Failed to load Image')
      }
    }
  }

  const handleImageRemove = () => {
    setSelectedImage(null)
    categoryForEdit.avatar = ''
    // formik.setFieldValue('avatar', '')
  }

  return (
    <>
      <Toaster />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          {/* {informationCategory.avatar ? ( */}
          <div className='fv-row mb-7 text-center'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url('${blankImg}')`}}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{
                  backgroundImage: `url('${
                    categoryForEdit?.avatar ? categoryForEdit?.avatar : selectedImage
                  }')`,
                }}
              ></div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input
                  type='file'
                  onChange={handleImageChange}
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                />
                <input type='hidden' name='avatar_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
                onClick={handleImageRemove}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={handleImageRemove}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
            {/* end::Hint */}
          </div>
          {/* ) : null} */}
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Title'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='  fw-bold fs-6 mb-2'>Description</label>
            {/* end::Label */}
            <textarea
              rows={4}
              placeholder='Description'
              {...formik.getFieldProps('description')}
              name='description'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
            ></textarea>

            {/* end::Input */}
          </div>

          {/* end::Input group */}
          {informationCategory.id ? (
            <div className='mb-7 d-flex fv-row align-items-center '>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 me-10'>Status</label>
              {/* end::Label */}
              {/* begin::Roles */}
              <div className='d-flex fv-row align-items-center justify-content-around '>
                {/* begin::Input row */}
                <div className='d-flex fv-row me-10'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('status')}
                      name='status'
                      type='radio'
                      value='Active'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.status === 'Active'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>Active</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                {/* end::Input row */}
                {/* begin::Input row */}
                <div className='d-flex fv-row'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('status')}
                      name='status'
                      type='radio'
                      value='Inactive'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.status === 'Inactive'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>Inactive</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                {/* end::Input row */}
              </div>
              {/* end::Roles */}
            </div>
          ) : null}
        </div>
        {/* end::Scroll */}
        <label className=' lbl_styl'>
          Fields marked with <span className='required'></span> are mandatory
        </label>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>
          {/* disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched} */}
          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'>
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loading />}
    </>
  )
}

export {InformationEditModalForm}
