/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'

// import {isEmpty} from 'lodash'
// import {useAuth} from '../auth'
// import {Loading} from '../data-table/components/loading/Loading'
import {Link} from 'react-router-dom'
// import {convertFileToBase64} from '../../../utils/helperFn'
// import {updateUserProfilePic} from '../apps/user-management/users-list/core/_requests'

const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

const ContentManagement: React.FC = () => {
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    Content Management
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/content-management/social-media-link' && 'active')
                }
                to='/content-management/social-media-link'
              >
                Social Media Links
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/content-management/footer-links' && 'active')
                }
                to='/content-management/footer-links'
              >
                Footer Links
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/content-management/slider-form' && 'active')
                }
                to='slider-form'
              >
                Slider Changes
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/content-management/contact-info' && 'active')
                }
                to='/content-management/contact-info'
              >
                Contact Info
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary` +
                  (location.pathname === '/content-management/promotions-content' && 'active')
                }
                to='/content-management/promotions-content'
              >
                Promotions Content
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ContentManagement}
