const QUERIES = {
  USERS_LIST: 'users-list',
  NEWS_LETTER_USERS: 'news-letter-users',
  COMMUNITY_POST: 'community-post',
  OFFER_LIST: 'offer-list',
  BLOG_LIST: 'blog-list',
  BLOG_COMMENTS: 'blog-comments',
  BUSINESS_COMMENTS: 'business-comments',
  BLOG_CATEGORY_LIST: 'blog-category-list',
  NEWS_LIST: 'news-list',
  NEWS_CATEGORY_LIST: 'news-category-list',
  SERVICES_LIST: 'professional-services-list',
  JOB_LIST: 'job-list',
  PLAN_LIST: 'plan-list',
  OFFER_TYPES: 'offer-types',
  BUSINESS_LIST: 'business-list',
  REAL_ESTATE_LIST: 'real-estate-list',
  BUSINESS_CATEGORY_LIST: 'business-category',
  INFORMATION_CATEGORY_LIST: 'information-category',
  INFO_DEPARTMENT_LIST: 'info-department-list',
  MAIN_CATEGORY_LIST: 'main-category-list',
  POST_COMMENTS: 'Community-post-comments',
  POST_CATEGORY_LIST: 'Community-post-category',
  ENQUIRY_LIST: 'enquiry-list',
  FAQ_QNA_URL: 'faq-qna-list',
  GEN_QNA_URL: 'gen-cat-list',
  GEN_EMAIL_URL: 'gen-email-list',
  FTR_QNA_URL: 'ftr-cat-list',
  NOTIFICATION_URL: 'ftr-hed-list',
  HDR_QNA_URL: 'ftr-cat-list',
  PRODUCT_URL: 'product-list',
}

export {QUERIES}
