import React from 'react'

const Features = ({amenities}) => {
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header border-1'>
        <h3 className='card-title fw-bold text-dark'>Amenities</h3>
      </div>
      <div className='card-body pt-3'>
        <ul className='list_wrapper'>
          {amenities.map((item, index) => (
            <li key={index}>
              <i className='fa-solid fa-check'></i>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export {Features}
