/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import {SidebarMenuItem} from './SidebarMenuItem'
const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-1'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Type of Businesses
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/business-categories'
        icon='office-bag'
        title='Business Category'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/businesses/list'
        icon='office-bag'
        title='Business Listings'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/offers-types'
        icon='discount'
        title='Offers Listings'
        fontIcon='bi-app-indicator'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Products</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/product-management'
        icon='briefcase'
        title='Product Management'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Real Estate</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/real-estate'
        icon='pointers'
        title='Real Estate management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Jobs</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/job-management'
        icon='briefcase'
        title='Job Management'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Information</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/information-categories'
        icon='information'
        title='Information List'
        fontIcon='bi-vector-pen'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Blogs</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/blog-categories'
        icon='notepad-edit'
        title='Blog Management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>News</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/news-categories'
        icon='document'
        title='News Management'
        fontIcon='bi-news'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Plan</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/plans'
        icon='crown-2
'
        title='Plans'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Community</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/community-corner'
        icon='people'
        title='Community Corner'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Payments</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/payments'
        icon='credit-cart'
        title='Payments'
        fontIcon='bi-cash-stack'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='user'
        title='User management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/user-enquiries'
        icon='directbox-default'
        title='User Enquiries'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Main</span>
        </div>
      </div>
      {/* <SidebarMenuItem
        to='/main-categories'
        icon='element-5'
        title='Category List'
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to='/professional-services'
        icon='brifecase-cros'
        title='Professional Services'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>FAQ Information</span>
        </div>
      </div>
      <SidebarMenuItem to='/faqs' icon='question' title='FAQ Management' fontIcon='bi-layers' />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/content-management'
        icon='element-5'
        title='Content Management'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
