import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Enquiry, EnquiryQueryResponse} from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const Enquiry_URL = `${API_URL}/inquiry`
const getEnquiries = (query: string): Promise<EnquiryQueryResponse> => {
  return axios.get(`${Enquiry_URL}?${query}`).then(({data}: AxiosResponse) => {
    return data
  })
}

const getEnquiryById = (id: ID): Promise<Enquiry | undefined> => {
  return axios.get(`${Enquiry_URL}/${id}`).then(({data}: AxiosResponse) => {
    const {_id, __v, ...businessCategory} = data.data
    const modifiedUser: Enquiry = {id: _id, ...businessCategory}
    return modifiedUser
  })
}
const exportEnquiryList = () => {
  return axios.get(`${Enquiry_URL}/exportEnquiry`).then(({data}: AxiosResponse) => {
    return data
  })
}
const removeExportedEnquiryFile = (fileData): Promise<void> => {
  return axios.post(`${Enquiry_URL}/exportFileRemove`, fileData).then(() => {})
}

const createEnquiry = (offer: Enquiry): Promise<Enquiry | undefined> => {
  return axios
    .post(Enquiry_URL, offer)
    .then((response: AxiosResponse<Response<Enquiry>>) => response.data)
    .then((response: Response<Enquiry>) => response.data)
}

const updateEnquiry = (offer: Enquiry): Promise<Enquiry | undefined> => {
  return axios
    .put(`${Enquiry_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<Enquiry>>) => response.data)
    .then((response: Response<Enquiry>) => response.data)
}

const deleteEnquiry = (categoryId: ID): Promise<void> => {
  return axios.delete(`${Enquiry_URL}/${categoryId}`).then(() => {})
}

const deleteSelectedEnquiries = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.delete(`${Enquiry_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteEnquiry,
  deleteSelectedEnquiries,
  updateEnquiry,
  getEnquiryById,
  createEnquiry,
  getEnquiries,
  exportEnquiryList,
  removeExportedEnquiryFile,
}
