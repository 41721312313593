import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Enquiry} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createEnquiry, updateEnquiry} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Loading} from '../components/loading/Loading'

type Props = {
  isLoading: boolean
  enquiry: Enquiry
}
const editEnquirySchema = Yup.object().shape({
  email: Yup.string().email('email must be valid').required('email is required'),
  reply: Yup.string().required('Message is required'),
})

const EnquiryEditModalForm: FC<Props> = ({enquiry, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [enquiryForEdit] = useState<Enquiry>({
    ...enquiry,
  })
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const selectedImagePlaceholder = toAbsoluteUrl(`/media/${enquiryForEdit.avatar}`)
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: enquiryForEdit,
    validationSchema: editEnquirySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const updateValues = {
        ...values,
      }
      try {
        if (isNotEmpty(values.id)) {
          await updateEnquiry(updateValues)
          cancel(true)
        } else {
          await createEnquiry(updateValues)
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              type='text'
              name='email'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
        </div>
        {/* end::Scroll */}

        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Reply Message</label>

          <textarea
            placeholder='reply'
            {...formik.getFieldProps('reply')}
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {
                'is-invalid': formik.touched.reply && formik.errors.reply,
              },
              {
                'is-valid': formik.touched.reply && !formik.errors.reply,
              }
            )}
            name='reply'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
          ></textarea>

          {formik.touched.reply && formik.errors.reply && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.reply}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {EnquiryEditModalForm}
