import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Business, BusinessQueryResponse } from './_models'
import _ from 'lodash'
const API_URL = process.env.REACT_APP_API_URL
const BUSINESS_URL = `${API_URL}/businesses`

const getAllBusiness = (query: string, categoryId: string): Promise<BusinessQueryResponse> => {
  return axios
    .get(`${BUSINESS_URL}/admin?category=${categoryId}&${query}`)
    .then(({ data }: AxiosResponse) => {
      return data
    })
}

const getAllBusinessListall = (query: string): Promise<BusinessQueryResponse> => {
  return axios.get(`${BUSINESS_URL}/admin/businesslist?${query}`).then(({ data }: AxiosResponse) => {
    return data
  })
}
const getAllBusinessList = (): Promise<BusinessQueryResponse> => {
  return axios.get(`${BUSINESS_URL}/admin/businesses`).then(({ data }: AxiosResponse) => {
    return data
  })
}
const removeBusinessImage = (realEstateId: ID, data: any): Promise<void> => {
  return axios
    .post(`${BUSINESS_URL}/${realEstateId}/removeImage`, data)
    .then((response) => response.data)
}
const importBusinesses = (bulkData): Promise<Business | undefined> => {
  return axios
    .post(`${BUSINESS_URL}/admin`, bulkData)
    .then((response: AxiosResponse<Response<Business>>) => response.data)
    .then((response: Response<Business>) => response.data)
}

const getBusinessById = (id: ID): Promise<Business | undefined> => {
  return axios.get(`${BUSINESS_URL}/${id}`).then(({ data }: AxiosResponse) => {
    return data.data
  })
}

const createBusiness = (business: Business): Promise<Business | undefined> => {
  return axios
    .post(BUSINESS_URL, business)
    .then((response: AxiosResponse<Response<Business>>) => response.data)
    .then((response: Response<Business>) => response.data)
}

const updateBusiness = (business: Business): Promise<Business | undefined> => {
  return axios
    .put(`${BUSINESS_URL}/${business.id}`, business)
    .then((response: AxiosResponse<Response<Business>>) => response.data)
    .then((response: Response<Business>) => response.data)
}
const updateBusinessRating = (business: Business): Promise<Business | undefined> => {
  return axios
    .put(`${BUSINESS_URL}/${business.id}`, business)
    .then((response: AxiosResponse<Response<Business>>) => response.data)
    .then((response: Response<Business>) => response.data)
}

const deleteBusiness = (businessId: ID): Promise<void> => {
  return axios.delete(`${BUSINESS_URL}/${businessId}`).then(() => { })
}

const deleteSelectedBusiness = (businessIds: Array<ID>): Promise<void> => {
  const requests = businessIds.map((id) => axios.delete(`${BUSINESS_URL}/${id}`))
  return axios.all(requests).then(() => { })
}
const restoreBusiness = (businessId: ID): Promise<void> => {
  return axios.get(`${BUSINESS_URL}/restore/${businessId}`).then(() => { })
}

const restoreSelectedBusiness = (businessIds: Array<ID>): Promise<void> => {
  const requests = businessIds.map((id) => axios.get(`${BUSINESS_URL}/restore/${id}`))
  return axios.all(requests).then(() => { })
}

export {
  restoreSelectedBusiness,
  getAllBusinessListall,
  restoreBusiness,
  deleteBusiness,
  getAllBusinessList,
  removeBusinessImage,
  importBusinesses,
  deleteSelectedBusiness,
  getBusinessById,
  updateBusinessRating,
  createBusiness,
  updateBusiness,
  getAllBusiness,
}
