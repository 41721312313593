import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  paymentId?: string
}

const Transaction: FC<Props> = ({paymentId}) => (
  <div className='d-flex flex-column '>
    <p className='mb-1 text-sm'>{paymentId}</p>
  </div>
)

export {Transaction}
