import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {Filter} from './Filter'

const ListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <Filter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add New
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {ListToolbar}
