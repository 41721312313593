import {useRef, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {SampleExcelModal} from './SampleExcelModal'
import {UsersListFilter} from './UsersListFilter'
import {ListFailedProvider} from '../../core/ListFailedProvider'

const UsersListToolbar = ({addNew = 'Add'}) => {
  const {setItemIdForUpdate} = useListView()
  const [isModalOpen, setIsModalOpen] = useState<Boolean>(false)
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const openSampleModal = () => {
    setIsModalOpen(true)
  }
  const closeSampleModal = (val: Boolean) => {
    setIsModalOpen(false)
  }

  return (
    <>
      <ListFailedProvider>
        {isModalOpen && (
          <SampleExcelModal
            fileName={'sample_business_data.xls'}
            closeSampleModal={closeSampleModal}
          />
        )}
      </ListFailedProvider>

      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <UsersListFilter />
        <button onClick={openSampleModal} type='button' className='btn btn-light-primary me-3'>
          <KTIcon iconName='exit-down' className='fs-2' />
          Import
        </button>
        {/* end::Export */}

        {/* begin::Add user */}
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          {addNew}
        </button>
        {/* end::Add user */}
      </div>
    </>
  )
}

export {UsersListToolbar}
