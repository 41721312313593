import {BusinessesFailedTable} from '../../Failed-list-table/BusinessesFailedTable'
import {useListFailedView} from '../../core/ListFailedProvider'
import _ from 'lodash'
export const FailedRecords = () => {
  const {data} = useListFailedView()
  return (
    <div className=''>
      {!_.isEmpty(data.invalidBusiness) ? (
        <div className=''>
          <h2 className='fw-bolder text-center pb-2 border-bottom-1'>Invalid Format Records</h2>
          <BusinessesFailedTable business={data.invalidBusiness} key={'invalid_format'} />
        </div>
      ) : null}
      {!_.isEmpty(data.existingBusiness) ? (
        <div className=''>
          <h2 className='fw-bolder text-center  pb-2 border-bottom-1'>Existing Records</h2>
          <BusinessesFailedTable business={data.existingBusiness} key={'exist-record'} />
        </div>
      ) : null}
    </div>
  )
}
