import {KTCard} from '../../../../../_metronic/helpers'
import {BusinessEditModal} from '../components/business-add-edit/BusinessEditModal'
import {UsersListHeader} from '../components/header/UsersListHeader'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {BusinessesTable} from '../table/BusinessesTable'

const BusinessList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <BusinessesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <BusinessEditModal />}
    </>
  )
}

const BusinessListWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <BusinessList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {BusinessListWrapper}
